import axios from 'axios'

function requestSubjectPage(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/subject/page`, parameter)
}
function requestSubjectUpdate(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/subject/update`, parameter)
}
function requestSubjectInsert(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/subject/individual/insert`, parameter)
}
function requestSubjectDuplicateMobileCheck(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/subject/duplicateMobile/select`,parameter)
}
function requestSubjectFileUpload(parameter) {
    const formData = new FormData()
    formData.append('excelFile', parameter.excelFile)
    return axios.post(`${process.env.VUE_APP_BASEURL}/subject/uploadSubjectFile`, formData)
}
function requestSubjectsInsert(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/subject/list/insert`, parameter)
}
function requestDubSubjectsExcelDownload(parameter){
    return  axios.post(`${process.env.VUE_APP_BASEURL}/subject/duplicateMobile-excel/download`, parameter, {responseType: 'blob'})  
}   
function requestSubjectUploadSampleDownload(parameter){
    return  axios.post(`${process.env.VUE_APP_BASEURL}/subject/upload-sample/download`, parameter, {responseType: 'blob'})  
}   

export {
    requestSubjectPage,
    requestSubjectUpdate,
    requestSubjectInsert,
    requestSubjectDuplicateMobileCheck,
    requestSubjectFileUpload,
    requestSubjectsInsert,
    requestDubSubjectsExcelDownload,
    requestSubjectUploadSampleDownload
}