import {
    requestAsStaticComfirmList,
    requestAsStaticComfirmExcelDownload
} from "../apis/as_api";



export const as_staticcomfirm = {
    nemespaced: true,
    
    state: () => ({
        asStaticComfirmItems:[],
        asStaticComfirmTotalCount:0,
    }),

    getters: {
        getAsStaticComfirmItems(state){
            return state.asStaticComfirmItems
        },
        getAsStaticComfirmTotalCount(state){
            return state.asStaticComfirmTotalCount
        },
    },

    mutations: {
        REQUEST_AS_STATIC_COMFIRM_LIST(state,data){
            
            state.asStaticComfirmItems = data.data
            state.asStaticComfirmTotalCount = data.totalCount
        },
        REQUEST_AS_STATIC_COMFIRM_LIST_INIT(state){
            state.asStaticComfirmItems = []
            state.asStaticComfirmTotalCount = 0
        },
    },

    actions: {
        async REQUEST_AS_STATIC_COMFIRM_LIST(context,parameter) {
            context.commit('START_LOADING');
            try{
                const response = await requestAsStaticComfirmList(parameter.searchAs);
                if(response.status === 200){
                    context.commit('REQUEST_AS_STATIC_COMFIRM_LIST',response.data);
                }
            }catch(e){
                console.log(e.response.data.resultMsg)  
            }
            context.commit('END_LOADING');
        },
        async REQUEST_AS_STATIC_COMFIRM_EXCEL_DOWNLOAD(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestAsStaticComfirmExcelDownload(parameter.searchAs);
                if(response.status === 200){
                    const url = URL.createObjectURL(new Blob([response.data], {
                        type: 'application/vnd.ms-excel; charset=euc-kr'
                      }))
                      const link = document.createElement('a')
                      link.href = url
                      link.setAttribute('download', "유지보수완료통계현황_"+ parameter.date +".xlsx")
                      document.body.appendChild(link)
                      link.click()
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
        },
    },

}