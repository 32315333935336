import axios from 'axios'

function requestOrgPage(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/orgs/page`, parameter)
}
function requestOrgManageUpdate(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/orgs-manage/update`, parameter)
}

function requestOrgSelect(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/orgs/select`, parameter)

}
export {
    requestOrgSelect,
    requestOrgPage,
    requestOrgManageUpdate,
}