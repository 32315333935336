<template>
  <CSidebar
    class="sidebar-background"
    position="fixed"
    :unfoldable="sidebarUnfoldable"
    :visible="sidebarVisible"
    @visible-change="
      (event) =>
        $store.commit({
          type: 'updateSidebarVisible',
          value: event,
        })
    "
  >
    <CSidebarBrand>
      <CDropdown dark class="sidebar-brand-full">
        <CDropdownToggle class="business-toggle" size="lg">{{ loginUserBusinessName }}</CDropdownToggle>
        <CDropdownMenu>
            <CDropdownItem 
              v-for="(item, index) in loginUserBusinessList" 
              :key="index" 
              @click="changeBusiness(item)"
            >
              {{ item.label }}
            </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>
    </CSidebarBrand>
    <AppSidebarNav />
    <CSidebarToggler
      class="d-none d-lg-flex"
      @click="$store.commit('toggleUnfoldable')"
    />
  </CSidebar>
</template>

<script>
import { computed } from 'vue'
import { mapGetters,useStore } from 'vuex'
import { AppSidebarNav } from './AppSidebarNav'
import { logoNegative } from '@/assets/brand/logo-negative'
import { sygnet } from '@/assets/brand/sygnet'
export default {
  name: 'AppSidebar',
  components: {
    AppSidebarNav,
  },
  setup() {
    const store = useStore()
    return {
      logoNegative,
      sygnet,
      sidebarUnfoldable: computed(() => store.state.sidebarUnfoldable),
      sidebarVisible: computed(() => store.state.sidebarVisible),
      loginUserBusinessList: computed(() => store.getters["getLoginUserBusinessList"]),
      loginUserBusinessName: computed(() => store.getters["getLoginUserBusinessName"]),
    }
  },
  mounted(){
    this.$store.commit("REQUEST_LOGIN_USER_BUSINESS_INFO")
  },
  beforeUnmount(){

  },
  computed:{
    },
  methods:{
    changeBusiness(item){
      this.$store.dispatch("REQUEST_CHANGE_BUSINESS", item).then( async(data) => {
        await this.$store.dispatch('REQUEST_COMMON_DATA')
        this.$router.go();
      })
      this.$router.push("/");
    }
  },
}
</script>
