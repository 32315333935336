<template>
    <router-view></router-view>
    <div v-if="getLoadingFlag" class="spinner_backgroud">
      <CSpinner color="warning"/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    name: 'App',
    components: {},
    data() {

    },
    computed:{
        ...mapGetters(['loginMenuItems','getLoadingFlag']),
    },
}
</script>
<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
</style>
