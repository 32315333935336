<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid>
      <div class="header-left">
        <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
          <CIcon icon="cil-menu" size="lg" />
        </CHeaderToggler>
        <div class="aboutUs" @click="zoomModal()">
          홈페이지
        </div>
      </div>
        <CImage @click="$router.push('/')" fluid :src="logo" style="height:38px;position: absolute;left: 50%;"/>
      <CHeaderNav>
        <AppHeaderDropdownAccnt />
      </CHeaderNav>
    </CContainer>
    <CHeaderDivider />
    <!-- <CContainer fluid>
      <AppBreadcrumb />
    </CContainer> -->
    <CommonHeaderImgZoomModal :label="'홈페이지'"/>
  </CHeader>
</template>

<script>
import AppBreadcrumb from './AppBreadcrumb'
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import CommonHeaderImgZoomModal from '@/components/common/CommonHeaderImgZoomModal'
import logo from '@/assets/images/logo.png'

export default {
  name: 'AppHeader',
  components: {
    AppBreadcrumb,
    AppHeaderDropdownAccnt,
    CommonHeaderImgZoomModal
  },
  setup() {
    return {
      logo,
      images:[
        {path:require('../assets/images/aboutUs/aboutus_1.png'), loadFile:false},
        {path:require('../assets/images/aboutUs/aboutus_2.png'), loadFile:false},
        {path:require('../assets/images/aboutUs/aboutus_3.png'), loadFile:false},
        {path:require('../assets/images/aboutUs/aboutus_4.png'), loadFile:false},
        {path:require('../assets/images/aboutUs/aboutus_5.png'), loadFile:false},
        {path:require('../assets/images/aboutUs/aboutus_6.png'), loadFile:false},
        {path:require('../assets/images/aboutUs/aboutus_7.png'), loadFile:false},
        {path:require('../assets/images/aboutUs/aboutus_8.png'), loadFile:false},
        {path:require('../assets/images/aboutUs/aboutus_9.png'), loadFile:false},
        {path:require('../assets/images/aboutUs/aboutus_10.png'), loadFile:false},
        {path:require('../assets/images/aboutUs/aboutus_11.png'), loadFile:false},
        {path:require('../assets/images/aboutUs/aboutus_12.png'), loadFile:false},
        {path:require('../assets/images/aboutUs/aboutus_13.png'), loadFile:false},
        {path:require('../assets/images/aboutUs/aboutus_14.png'), loadFile:false},
        {path:require('../assets/images/aboutUs/aboutus_15.png'), loadFile:false},
      ]
    }
  },
  methods: {
    zoomModal(){
      window.open(`https://www.hansolution.net`);
      return;
      const param = {
        images: this.images,
        index: 0
      }
      this.emitter.emit('CommonHeaderImgZoomModal', param)
    }
  },
}
</script>
