import {
    requestInstPage,
    requestInstSelect,
    requestInstExcelDownload
} from "../apis/inst_api";

import router from "@/router";

const pageType = 'cancel'

export const inst_cancel = {
    nemespaced: true,
    
    state: () => ({
        instCancelStateCd:'STE004', //진행코드

        instCancelItems:[],
        instCancelTotalCount:0,
        instCancelItem:{},

    }),

    getters: {
        getInstCancelStateCd(state){
            return state.instCancelStateCd
        },
        getInstCancelItems(state){
            return state.instCancelItems
        },
        getInstCancelTotalCount(state){
            return state.instCancelTotalCount
        },
        getInstCancelItem(state){
            return state.instCancelItem
        },
    },

    mutations: {
        REQUEST_INST_CANCEL_PAGE(state,data){
            state.instCancelItems = data.data
            state.instCancelTotalCount = data.totalCount
        },
        REQUEST_INST_CANCEL_PAGE_INIT(state){
            state.instCancelItems = []
            state.instCancelTotalCount = 0
        },
        REQUEST_INST_CANCEL_SELECT_INIT(state){
            state.instCancelItem = {}
        },
        REQUEST_INST_CANCEL_SELECT(state,data){
            state.instCancelItem = data.data
        },
    },

    actions: {
        async REQUEST_INST_CANCEL_PAGE(context,parameter) {
            context.commit('START_LOADING');
            try{
                const response = await requestInstPage(parameter.searchInst,pageType);
                if(response.status === 200){
                    context.commit('REQUEST_INST_CANCEL_PAGE',response.data);
                }
            }catch(e){
                console.log(e.response.data.resultMsg)
            }
            context.commit('END_LOADING');
        },
        async REQUEST_INST_CANCEL_SELECT(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestInstSelect(parameter.searchInst,pageType);
                if(response.status === 200){
                    context.commit('REQUEST_INST_CANCEL_SELECT',response.data);
                }
            }catch(e){
                console.log(e.response.data.resultMsg)
            }
            context.commit('END_LOADING');
        },
        async REQUEST_INST_CANCEL_EXCEL_DOWNLOAD(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestInstExcelDownload(parameter.searchInst,pageType);
                if(response.status === 200){
                    const url = URL.createObjectURL(new Blob([response.data], {
                        type: 'application/vnd.ms-excel; charset=euc-kr'
                      }))
                      const link = document.createElement('a')
                      link.href = url
                      link.setAttribute('download', "설치취소list_"+ parameter.date +".xlsx")
                      document.body.appendChild(link)
                      link.click()
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
        },
    },

}