import axios from 'axios'

function requestInstPage(parameter,page){
    return axios.post(`${process.env.VUE_APP_BASEURL}/inst/${page}/page`,parameter)
}
function requestInstStateUpdate(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/inst/set/state/update`, parameter)
}
function requestInstAssignCancel(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/inst/set/assign-cancel/update`, parameter)
}
function requestInstListStateUpdate(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/inst/set/list-state/update`, parameter)
}
function requestInstSelect(parameter,page){
    return axios.post(`${process.env.VUE_APP_BASEURL}/inst/${page}/select`,parameter)
}
function requestInstUpdate(parameter,page){
    return axios.post(`${process.env.VUE_APP_BASEURL}/inst/${page}/update`,parameter)
}
function requestInstExcelDownload(parameter,page){
    return  axios.post(`${process.env.VUE_APP_BASEURL}/inst/${page}/excel/download`, parameter, {responseType: 'blob'})  
} 
function requestInstInsert(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/subject/inst/insert`, parameter)
}
function requestInstsInsert(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/subject/inst/list/insert`, parameter)
}
function requestCheckListPage(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/current/checklist/page`,parameter)
}
function requestInstBatchDownload(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/current/checklist/download`, parameter, {responseType: 'blob'})
}
function requestInstStatisticPage(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/current/statistic/inst/page`, parameter)
}
function requestInstStatisticExcelDownload(parameter){
    return  axios.post(`${process.env.VUE_APP_BASEURL}/current/statistic/inst/excel/download`, parameter, {responseType: 'blob'})  
} 
function requestInstPdfFinishTrans(parameter){
    return  axios.post(`${process.env.VUE_APP_BASEURL}/inst/finish/pdf/trans`,parameter)  
} 
function requestInstEngineerSelect(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/current/completelist/completePdf-engineer/select`,parameter)
}
function requestInstCompletePdfFormSelect(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/current/completelist/completePdf-form/select`,parameter)
}
function requestCompletePdfInsert(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/current/completelist/completePdf/insert`, parameter)
}
function requestCompletePdfUpdate(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/current/completelist/completePdf/update`, parameter)
}
function requestCompleteListPage(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/current/completelist/page`,parameter)
}
function requestCompleteListSelect(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/current/completelist/select`,parameter)
}
function requestCompleteListDelete(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/current/completelist/completePdf/delete`,parameter)
}
function requestInstInitInspector(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/current/checklist/init-inspector/update`,parameter)
}

function requestInstInitInspectors(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/current/checklist/init-inspectors/update`,parameter)
}

function requestInstFinishFileDownload(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/inst/finish-picture/pc/download`,parameter, {responseType: 'blob'})

}


export {
    requestInstPage,
    requestInstStateUpdate,
    requestInstAssignCancel,
    requestInstListStateUpdate,
    requestInstSelect,
    requestInstUpdate,
    requestInstExcelDownload,
    requestInstInsert,
    requestInstsInsert,
    requestCheckListPage,
    requestInstBatchDownload,
    requestInstStatisticPage,
    requestInstStatisticExcelDownload,
    requestInstPdfFinishTrans,
    requestInstEngineerSelect,
    requestInstCompletePdfFormSelect,
    requestCompletePdfInsert,
    requestCompletePdfUpdate,
    requestCompleteListPage,
    requestCompleteListSelect,
    requestCompleteListDelete,
    requestInstInitInspector,
    requestInstInitInspectors,
    requestInstFinishFileDownload
}