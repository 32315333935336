import {
	requestUserInsert,
    requestLogin,
    requestUserPage,
    requesUserBusinessList,
    requestUserUpdateForAdmin,
    requestInstallerList,
    requestUserSelect,
    requestFindUser,
    requestFindUserId,
    requestUserElecContractUpload,
    requestUserElecContractDownload,
    requesUserInfoBusinessList,
    requestUserInfoUpdate,
    requestUserInfoSelect,
    requestLogout
} from "../apis/user_api";
import {
	deleteTokenInLocalStorage,
	setTokenInLocalStorage,
    setBusinessInfoLocalStorage
} from "@/utils/oauth";
import { deleteAccessTokenInHeader } from "../apis/common_api";

import router from "@/router";

export const user = {
    nemespaced: true,
    
    state: () => ({
        accessToken: localStorage.getItem("access_token"),

        user: {
            userId: localStorage.getItem("userId"),
            userName: localStorage.getItem("userName"),
            userMobile: localStorage.getItem("userMobile"),
            userAuthorityId: localStorage.getItem("userAuthorityId"),
            userBusinessMappingList: localStorage.getItem("userBusinessMappingList"),
        },

        userItems:[],
        userTotalCount:0,

        userBusinessItems:[],
        userAccountItems:[],

        installerItems:[],
        installerSelectBox:[{label: '선택', value: ''}],

        loginUserBusinessMappingList: localStorage.getItem("userBusinessMappingList"),
        loginUserBusinessName: localStorage.getItem("userBusinessName"),

        tempUser:null
    }),

    getters: {
        isAuthenticated(state) {
            return state.accessToken!==null && state.accessToken!==undefined && state.accessToken!=='';
        },
        getUserItems(state){
            return state.userItems
        },
        getUserTotalCount(state){
            return state.userTotalCount
        },
        getUserBusinessItems(state){
            return state.userBusinessItems
        },
        getUserAccountItems(state){
            return state.userAccountItems
        },
        getLoginUser(state){
            return state.user
        },
        getLoginUserAuthorityId(state){
            return state.user.userAuthorityId
        },
        getInstaller(state){
            return state.installerItems
        },
        getInstallerSelectBox(state){
            return state.installerSelectBox
        },
        getLoginUserBusinessList(state){
            return JSON.parse(state.loginUserBusinessMappingList)
        },
        getLoginUserBusinessName(state){
            return state.loginUserBusinessName
        },
        getUser(state){
            return state.user
        },
        getTempUser(state){
            return state.tempUser
        },
    },

    mutations: {
        LOGIN(state,data) {
            state.user.userId = data.userId
            state.user.userName = data.userName
            state.user.userAuthorityId = data.userAuthorityId

            const businessList = data.userBusinessMappingList
            state.user.userBusinessMappingList=[];
            for(let i=0; i<businessList.length; i++) {
                state.user.userBusinessMappingList.push({
                    label: businessList[i].businessName,
                    value: businessList[i].businessSchemaName,
                });
            }
            state.accessToken = localStorage.getItem("access_token");
            router.push("/");

            if(data.userTempPasswordYn){
                alert('임시비밀번호로 로그인 하셨습니다.\n비밀번호를 변경해주세요.')
            }
        },
        LOGOUT(state) {
            resetToken(state);
            router.push("/pages/login");
        },
        LOGOUT_WITH_TOKEN_INVALIDE(state) {		
            resetToken(state);
            router.push("/pages/login");
        },
        REQUEST_USER_PAGE(state,data){
            state.userItems = data.data
            state.userTotalCount = data.totalCount
        },
        REQUEST_USER_PAGE_INIT(state){
            state.userItems = []
            state.userTotalCount = 0
        },
        REQUEST_USER_BUSINESS_LIST(state,data){
            const businessList = data.data
            state.userBusinessItems=[];
            for(let i=0; i<businessList.length; i++) {
                state.userBusinessItems.push({
                    value: businessList[i].businessSchemaName,
                    label: businessList[i].businessName,
                })
                state.userAccountItems.push(businessList[i].accountId)
            }
        },
        REQUEST_USER_BUSINESS_MAPP_INIT(state){
            state.userBusinessItems = []
            state.userAccountItems = []
        },
        REQUEST_INSTALLER_INIT(state){
            state.installerItems = []
            state.installerSelectBox =[{label: '선택', value: ''}]
        },
        REQUEST_INSTALLER_LIST(state,data){
            
            state.installerItems = data.data

            const installerList = data.data
            for(let i=0; i<installerList.length; i++) {
                state.installerSelectBox.push({
                    label: installerList[i].userName,
                    value: installerList[i].userId,
                });
            }
        },
        REQUEST_LOGIN_USER_BUSINESS_INFO(state){
            state.loginUserBusinessMappingList = localStorage.getItem("userBusinessMappingList")
            state.loginUserBusinessName = localStorage.getItem("userBusinessName")
        },
        REQUEST_CHANGE_BUSINESS(state){
            state.loginUserBusinessName = localStorage.getItem("userBusinessName")
        },
        REQUEST_FIND_USER(state,data){
            state.tempUser = data
        },
        REQUEST_FIND_USER_INIT(state,data){
            state.tempUser = null
        },
        REQUEST_FIND_USER_ID(state,data){
            state.tempUser = data
        },
    },

    actions: {
        async REQUEST_USER_INSERT(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestUserInsert(parameter);
                if(response.status === 201){
                    parameter.context.complete();
                    alert("회원가입이 완료되었습니다!\n승인 받은 후 로그인 할 수 있습니다.")
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
    
        },
        async REQUEST_LOGIN(context, data) {
            try {
                context.commit('START_LOADING');
                const response = await requestLogin(data);

                if(response.data.userAuthorityId === '4'){
                    alert('모바일에서 로그인 해주세요.')
                    context.commit('END_LOADING');
                    return false
                }

                setTokenInLocalStorage(response.data);
    
                context.commit("LOGIN",response.data);
                context.commit('END_LOADING');
                return true;
            } catch (e) { 
                console.log(e.response.data.resultMsg)
                context.commit('END_LOADING');
                return false;
            }
        },
        async REQUEST_USER_PAGE(context,parameter) {
            context.commit('START_LOADING');
            try{
                const response = await requestUserPage(parameter.searchUserVO);
                if(response.status === 200){
                    context.commit('REQUEST_USER_PAGE',response.data);
                }
            }catch(e){
                console.log(e.response.data.resultMsg)
            }
            context.commit('END_LOADING');
        },
        async REQUEST_USER_BUSINESS_LIST(context,parameter) {
            context.commit('START_TABLE_LOADING');
            try{
                const response = await requesUserBusinessList(parameter.searchUserVO);
                if(response.status === 200){
                    context.commit('REQUEST_USER_BUSINESS_LIST',response.data);
                }
            }catch(e){
                console.log(e.response.data.resultMsg)
            }
            context.commit('END_LOADING');
        },
        async REQUEST_USER_UPDATE_FOR_ADMIN(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestUserUpdateForAdmin(parameter);
                if(response.status === 201){
                    parameter.context.complete();
                    alert("사용자정보 수정을 성공하였습니다.")
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
        },
        async REQUEST_INSTALLER_LIST(context,parameter,page) {
            context.commit('START_LOADING');
            try{
                const response = await requestInstallerList(parameter.searchUserVO, page);
                if(response.status === 200){
                    context.commit('REQUEST_INSTALLER_LIST',response.data);
                }
            }catch(e){
                console.log(e.response.data.resultMsg)
            }
            context.commit('END_LOADING');
        },
        async REQUEST_CHANGE_BUSINESS(context, data) {
            try {
                context.commit('START_LOADING');
                
                setBusinessInfoLocalStorage(data);
                context.commit("REQUEST_CHANGE_BUSINESS");

                context.commit('END_LOADING');
                return true;
            } catch (e) { 
                console.log(e.response.data.resultMsg)
                return false;
            }
        },
        async REQUEST_USER_SELECT(context,parameter) {
            context.commit('START_LOADING');
            try{
                const response = await requestUserSelect(parameter.searchUserVO);
                if(response.status === 200){
                    context.commit('END_LOADING');
                    return response.data
                }
            }catch(e){
                console.log(e.response.data.resultMsg)
            }
            context.commit('END_LOADING');
        },
        async REQUEST_USERINFO_UPDATE(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestUserInfoUpdate(parameter);
                if(response.status === 201){
                    parameter.context.complete();
                    alert("사용자정보 수정을 성공하였습니다.")
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
        },
        async REQUEST_FIND_USER(context,parameter) {
            context.commit('START_LOADING');
            try{
                const response = await requestFindUser(parameter.loginVO);
                if(response.status === 200){
                    context.commit("REQUEST_FIND_USER", response.data)
                    
                    if(this.getters.getTempUser.length === 0){
                        alert('존재하지 않는 사용자입니다.')
                      }
                }
            }catch(e){
                console.log(e.response.data.resultMsg)
            }
            context.commit('END_LOADING');
        },
        async REQUEST_FIND_USER_ID(context,parameter) {
            context.commit('START_LOADING');
            try{
                const response = await requestFindUserId(parameter.loginVO);
                if(response.status === 200){
                    context.commit("REQUEST_FIND_USER_ID", response.data);
                    if(this.getters.getTempUser.length === 0){
                        alert('인증번호가 올바르지 않습니다.')
                      }
                }
            }catch(e){
                console.log(e.response.data.resultMsg)
            }
            context.commit('END_LOADING');
        },
        async REQUEST_USER_ELEC_CONTRACT_UPLOAD(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestUserElecContractUpload(parameter);
                if(response.status === 201){
                    alert("전자계약서 등록이 완료되었습니다.")
                    context.commit('END_LOADING');
                    return response.data.data.fileManagerPath
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
        },
        async REQUEST_USER_ELEC_CONTRACT_DOWNLOAD(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestUserElecContractDownload(parameter.userVO);
                if(response.status === 200){
                    const url = URL.createObjectURL(new Blob([response.data], {
                        type: 'application/*; Content-Disposition;'
                      }))
                      const link = document.createElement('a')
                      link.href = url
                      link.setAttribute('download'
                                        , "전자계약서_"+ parameter.userVO.userId 
                                        + "_" + parameter.date 
                                        + '.' + parameter.userVO.electronicContractFile.split('.').reverse()[0])
                      document.body.appendChild(link)
                      link.click()
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
        },
        async REQUEST_USERINFO_BUSINESS_LIST(context,parameter) {
            context.commit('START_TABLE_LOADING');
            try{
                const response = await requesUserInfoBusinessList(parameter.searchUserVO);
                if(response.status === 200){
                    context.commit('REQUEST_USER_BUSINESS_LIST',response.data);
                }
            }catch(e){
                console.log(e.response.data.resultMsg)
            }
            context.commit('END_LOADING');
        },
        async REQUEST_USERINFO_SELECT(context,parameter) {
            context.commit('START_LOADING');
            try{
                const response = await requestUserInfoSelect(parameter.searchUserVO);
                if(response.status === 200){
                    context.commit('END_LOADING');
                    return response.data
                }
            }catch(e){
                console.log(e.response.data.resultMsg)
            }
            context.commit('END_LOADING');
        },
        async REQUEST_LOGOUT(context) {
            context.commit('START_LOADING');
            try{
                const response = await requestLogout();
                if(response.status === 200){
                    context.commit('LOGOUT');
                    alert('로그아웃 되었습니다.')
                }
            }catch(e){
                console.log(e.response.data.resultMsg)
            }
            context.commit('END_LOADING');
        },
    },

}

export function resetToken(state)  {
    deleteTokenInLocalStorage()
    deleteAccessTokenInHeader()
    state.accessToken = null
}