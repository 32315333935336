<template>
  <CModal
    :visible="headerImgZoomModal"
    scrollable
    alignment="center"
    size="xl"
    @close="
      () => {
        headerImgZoomModal = false
      }
    "
  >
    <CModalHeader
      dismiss
      @close="
        () => {
          headerImgZoomModal = false
        }
      "
    >
      <CModalTitle>{{label}}</CModalTitle>
    </CModalHeader>
    <CModalBody>
      <swiper 
        :direction="'vertical'"
        :slidesPerView="1"
        :spaceBetween="30"
        :mousewheel="true"
        :pagination="true"
        :modules="modules" 
        class="myZoomSwiper"
        @swiper="setSwiperRef"
        >
        <swiper-slide v-for="(image,i) in zoomImages" :key="i">
          <CImage class="sliderZoomImg" :src="makePath(image)"/>
        </swiper-slide>
      </swiper>
    </CModalBody>
  </CModal>
</template>
<script>
import { ref } from 'vue';
import { mapGetters } from "vuex";
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination, Navigation, Mousewheel } from 'swiper';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

export default {
  name: 'CommonHeaderImgZoomModal',
  props: ['label', 'text'],
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      modules: [Pagination, Navigation, Mousewheel],

      zoomImages:[],
      swiperIndex: 0,
      headerImgZoomModal:false,
      
      swiperRef:null,
    }
  },
  computed: {
    ...mapGetters(['getAppBaseUrl']),
  },
  mounted(){
    const context = this
    this.emitter.on('CommonHeaderImgZoomModal',(val) => {
      context.init();
      context.zoomImages = JSON.parse(JSON.stringify(val.images))
      context.swiperIndex = JSON.parse(val.index)
      context.headerImgZoomModal = true;
		});
  },
  beforeUnmount(){
    this.headerImgZoomModal = false
    this.emitter.off('CommonHeaderImgZoomModal')
  },
  methods: {
    init(){
      this.zoomImages = []
    },
    closeClick(){
      this.headerImgZoomModal = false
    },
    setSwiperRef(swiper){
      this.swiperRef = swiper;
      this.swiperRef.slideTo(this.swiperIndex, 0);
    },
    makePath(image){
      if(image.loadFile){
        return this.getAppBaseUrl+ '/display?fileSvrPath='+encodeURI(image.path)
      }else{
        return image.path
      }
    },
  },
}
</script>
<style>
.myZoomSwiper{
  height: 50rem;
}
.sliderZoomImg{
  height: 100%;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>

