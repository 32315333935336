import {
    requestAsPage,
    requestAsSelect,
    requestAsExcelDownload,
    requestAsPeriodOverExcelDownload,
    requestAsUpdate
} from "../apis/as_api";

import router from "@/router";

const pageType = 'ing'

export const as_ing = {
    nemespaced: true,
    
    state: () => ({
        asIngStateCd:'STE002', //진행코드

        asIngItems:[],
        asIngTotalCount:0,
        asIngItem:{},
    }),

    getters: {
        getAsIngStateCd(state){
            return state.asIngStateCd
        },
        getAsIngItems(state){
            return state.asIngItems
        },
        getAsIngTotalCount(state){
            return state.asIngTotalCount
        },
        getAsIngItem(state){
            return state.asIngItem
        },
    },

    mutations: {
        REQUEST_AS_ING_PAGE(state,data){
            state.asIngItems = data.data
            state.asIngTotalCount = data.totalCount
        },
        REQUEST_AS_ING_PAGE_INIT(state){
            state.asIngItems = []
            state.asIngTotalCount = 0
        },
        REQUEST_AS_ING_SELECT(state,data){
            state.asIngItem = data.data
            state.asIngItem.asRequestDesc = JSON.parse(state.asIngItem.asRequestDesc)
            let asRequestDescText = '';
            for(let i=0;i<state.asIngItem.asRequestDesc.length;i++){
                asRequestDescText += state.asIngItem.asRequestDesc[i].title + ' : ' + state.asIngItem.asRequestDesc[i].value  + '\n'
            }
            state.asIngItem.asRequestDescText = asRequestDescText
        },
        REQUEST_AS_ING_SELECT_INIT(state){
            state.asIngItem = {}
        },
    },

    actions: {
        async REQUEST_AS_ING_PAGE(context,parameter) {
            context.commit('START_LOADING');
            try{
                const response = await requestAsPage(parameter.searchAs,pageType);
                if(response.status === 200){
                    context.commit('REQUEST_AS_ING_PAGE',response.data);
                }
            }catch(e){
                console.log(e.response.data.resultMsg)  
            }
            context.commit('END_LOADING');
        },
        async REQUEST_AS_ING_SELECT(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestAsSelect(parameter.searchAs,pageType);
                if(response.status === 200){
                    context.commit('REQUEST_AS_ING_SELECT',response.data);
                }
            }catch(e){
                console.log(e.response.data.resultMsg)  
            }
            context.commit('END_LOADING');
        },
        async REQUEST_AS_ING_EXCEL_DOWNLOAD(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestAsExcelDownload(parameter.searchAs,pageType);
                if(response.status === 200){
                    const url = URL.createObjectURL(new Blob([response.data], {
                        type: 'application/vnd.ms-excel; charset=euc-kr'
                      }))
                      const link = document.createElement('a')
                      link.href = url
                      link.setAttribute('download', "AS진행list_"+ parameter.date +".xlsx")
                      document.body.appendChild(link)
                      link.click()
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
        },
        async REQUEST_AS_PERIOD_OVER_EXCEL_DOWNLOAD(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestAsPeriodOverExcelDownload(parameter.searchAs);
                if(response.status === 200){
                    const url = URL.createObjectURL(new Blob([response.data], {
                        type: 'application/vnd.ms-excel; charset=euc-kr'
                      }))
                      const link = document.createElement('a')
                      link.href = url
                      link.setAttribute('download', "AS_7일초과현황_"+ parameter.date +".xlsx")
                      document.body.appendChild(link)
                      link.click()
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
        },
        async REQUEST_AS_ING_UPDATE(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestAsUpdate(parameter.searchAs,pageType);
                if(response.status === 201){
                    alert("취소되었습니다.")
                    parameter.context.complete(parameter)
                }
            }catch(e){
                console.log(e.response.data.resultMsg)  
            }
            context.commit('END_LOADING');
        },
    },

}