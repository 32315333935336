import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'
import store from "@/store";

import DefaultLayout from '@/layouts/DefaultLayout'

const onlyAuth = (to, from, next) => {
  if (store.getters.getLoginUserAuthorityId === '4') {
    alert('모바일에서 로그인 해주세요.')
    store.dispatch('REQUEST_LOGOUT')
  }else{
    if (!store.getters.isAuthenticated) {
      // 아직 로그인이 안 되있는 유저니까 막아야 한다.
      next("/pages/login")
    } else {
      next()
    }
  }
}

const onlyNotAuth = (to, from, next) => {
	if (store.getters.isAuthenticated) {
    // 아직 로그인이 안 되있는 유저니까 막아야 한다.
		next("/")
	} else {
		next()
	}
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/dashboard',
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        beforeEnter: onlyAuth,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },
      {
        path: '/inst',
        name: '설치관리',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/inst/set/page',
        children: [
          {
            path: 'set',
            name: '설치예약현황',
            redirect: '/inst/set/page',
            children: [
              {        
                path: 'page',
                name: '설치예약현황조회',
                beforeEnter: onlyAuth,
                component: () => import('@/views/inst/set/Page'),
              },
              {        
                path: 'detail',
                name: '설치예약현황상세',
                beforeEnter: onlyAuth,
                component: () => import('@/views/inst/set/Detail'),
              },
            ]
          },
          {
            path: 'ing',
            name: '설치진행현황',
            redirect: '/inst/ing/page',
            children: [
              {        
                path: 'page',
                name: '설치진행현황조회',
                beforeEnter: onlyAuth,
                component: () => import('@/views/inst/ing/Page'),
              },
              {        
                path: 'detail',
                name: '설치진행현황상세',
                beforeEnter: onlyAuth,
                component: () => import('@/views/inst/ing/Detail'),
              },
            ]
          },
          {
            path: 'finish',
            name: '설치완료현황',
            redirect: '/inst/ing/page',
            children: [
              {        
                path: 'page',
                name: '설치완료현황조회',
                beforeEnter: onlyAuth,
                component: () => import('@/views/inst/finish/Page'),
              },
              {        
                path: 'detail',
                name: '설치완료현황상세',
                beforeEnter: onlyAuth,
                component: () => import('@/views/inst/finish/Detail'),
              },
            ]
          },  
          {
            path: 'cancel',
            name: '설치취소현황',
            redirect: '/inst/cancel/page',
            children: [
              {        
                path: 'page',
                name: '설치취소현황조회',
                beforeEnter: onlyAuth,
                component: () => import('@/views/inst/cancel/Page'),
              },
              {        
                path: 'detail',
                name: '설치취소현황상세',
                beforeEnter: onlyAuth,
                component: () => import('@/views/inst/cancel/Detail'),
              },
            ]
          },    
        ],
      },
      {
        path: '/as',
        name: 'AS관리',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/as/set/page',
        children: [
          {
            path: 'set',
            name: 'AS예약현황',
            redirect: '/as/set/page',
            children: [
              {        
                path: 'page',
                name: 'AS예약현황조회',
                beforeEnter: onlyAuth,
                component: () => import('@/views/as/set/Page'),
              },
              {        
                path: 'detail',
                name: 'AS예약현황상세',
                beforeEnter: onlyAuth,
                component: () => import('@/views/as/set/Detail'),
              },
            ]
          },
          {
            path: 'ing',
            name: 'AS진행현황',
            redirect: '/as/set/page',
            children: [
              {        
                path: 'page',
                name: 'AS진행현황조회',
                beforeEnter: onlyAuth,
                component: () => import('@/views/as/ing/Page'),
              },
              {        
                path: 'detail',
                name: 'AS진행현황상세',
                beforeEnter: onlyAuth,
                component: () => import('@/views/as/ing/Detail'),
              },
            ]
          }, 
          {
            path: 'finish',
            name: 'AS완료현황',
            redirect: '/as/finish/page',
            children: [
              {        
                path: 'page',
                name: 'AS완료현황조회',
                beforeEnter: onlyAuth,
                component: () => import('@/views/as/finish/Page'),
              },
              {        
                path: 'detail',
                name: 'AS완료현황상세',
                beforeEnter: onlyAuth,
                component: () => import('@/views/as/finish/Detail'),
              },
            ]
          },
          {
            path: 'cancel',
            name: 'AS취소현황',
            redirect: '/as/cancel/page',
            children: [
              {        
                path: 'page',
                name: 'AS취소현황조회',
                beforeEnter: onlyAuth,
                component: () => import('@/views/as/cancel/Page'),
              },
              {        
                path: 'detail',
                name: 'AS취소현황상세',
                beforeEnter: onlyAuth,
                component: () => import('@/views/as/cancel/Detail'),
              },
            ]
          },   
          
          
          {
            path: 'static',
            name: 'AS 접수 통계',
            redirect: '/as/static/page',
            children: [
              {        
                path: 'page',
                name: 'AS 접수 통계',
                beforeEnter: onlyAuth,
                component: () => import('@/views/as/static/Page'),
              },
              {        
                path: 'detail',
                name: 'AS 접수 통계 리스트',
                beforeEnter: onlyAuth,
                component: () => import('@/views/as/static/Detail'),
              },
            ]
          },
          {
            path: 'staticcomfirm',
            name: 'AS 완료 통계',
            redirect: '/as/staticcomfirm/page',
            children: [
              {        
                path: 'page',
                name: 'AS 완료 통계',
                beforeEnter: onlyAuth,
                component: () => import('@/views/as/staticcomfirm/Page'),
              },
              {        
                path: 'detail',
                name: 'AS 완료 통계 리스트',
                beforeEnter: onlyAuth,
                component: () => import('@/views/as/staticcomfirm/Detail'),
              },
            ]
          },
        ],
      },
      {
        path: '/current',
        name: '현황관리',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },	
        redirect: '/current/checklist/page',
        children: [
          {
            path: 'checklist/page',
            name: '설치점검표현황',
            beforeEnter: onlyAuth,
            component: () => import('@/views/current/checklist/Page'),
          },
          {
            path: 'completelist',
            name: '설치완료표',
            redirect: '/current/completelist/page',
            children: [
              {        
                path: 'page',
                name: '설치완료표조회',
                beforeEnter: onlyAuth,
                component: () => import('@/views/current/completeList/Page'),
              },
              {        
                path: 'create',
                name: '설치완료표작성',
                beforeEnter: onlyAuth,
                component: () => import('@/views/current/completeList/Create'),
              },
              {        
                path: 'modify',
                name: '설치완료표수정',
                beforeEnter: onlyAuth,
                component: () => import('@/views/current/completeList/Modify'),
              },
            ]
          },
          {
            path: 'statistic/page',
            name: '전체통계현황',
            beforeEnter: onlyAuth,
            component: () => import('@/views/current/statistic/Page'),
          },
          {
            path: 'period-over',
            name: '7일초과현황',
            redirect: '/current/period-over/page',
            children: [
              {        
                path: 'page',
                name: '7일초과현황조회',
                beforeEnter: onlyAuth,
                component: () => import('@/views/current/periodOver/Page'),
              },
              {        
                path: 'detail',
                name: '7일초과현황상세',
                beforeEnter: onlyAuth,
                component: () => import('@/views/current/periodOver/Detail'),
              },
            ]
          },
        ]
      },
      {
        path: '/board',
        name: '업무게시판',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },	
        redirect: '/board/notice/page',
        children: [
          {
            path: 'notice',
            name: '공지사항',
            redirect: '/board/notice/page',
            children: [
              {        
                path: 'page',
                name: '공지사항조회',
                beforeEnter: onlyAuth,
                component: () => import('@/views/board/notice/Page'),
              },
              {        
                path: 'create',
                name: '공지사항등록',
                beforeEnter: onlyAuth,
                component: () => import('@/views/board/notice/Create'),
              },
              {        
                path: 'modify',
                name: '공지사항수정',
                beforeEnter: onlyAuth,
                component: () => import('@/views/board/notice/Modify'),
              },
              {        
                path: 'detail',
                name: '공지사항상세',
                beforeEnter: onlyAuth,
                component: () => import('@/views/board/notice/Detail'),
              },
            ]
          },
        ]
      },
      {
        path: '/subject',
        name: '대상자관리',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/subject/page',
        children: [
          {
            path: 'page',
            name: '대상자조회',
            beforeEnter: onlyAuth,
            component: () => import('@/views/subject/Page'),
          },
        ],
      },
      {
        path: '/system',
        name: '시스템관리',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/system/user/page',
        children: [
          {
            path: 'user/page',
            name: '사용자관리',
            beforeEnter: onlyAuth,
            component: () => import('@/views/system/user/Page'),
          },
          {
            path: 'auth/page',
            name: '권한관리',
            beforeEnter: onlyAuth,
            component: () => import('@/views/system/auth/Page'),
          },
          {
            path: 'account',
            name: '거래처관리',
            redirect: '/system/account/page',
            children: [
              {        
                path: 'page',
                name: '거래처조회',
                beforeEnter: onlyAuth,
                component: () => import('@/views/system/account/Page'),
              },
              {        
                path: 'create',
                name: '거래처생성',
                beforeEnter: onlyAuth,
                component: () => import('@/views/system/account/Create'),
              },
              {        
                path: 'modify',
                name: '거래처수정',
                beforeEnter: onlyAuth,
                component: () => import('@/views/system/account/Modify'),
              },
            ]
          },
          {
            path: 'business-code/page',
            name: '사업단코드관리',
            beforeEnter: onlyAuth,
            component: () => import('@/views/system/businessCode/Page'),
          },
        ],
      },
      {
        path: '/master',
        name: '전체관리자전용',
        component: {
          render() {
            return h(resolveComponent('router-view'))
          },
        },
        redirect: '/system/business/page',
        children: [
          {
            path: '/system/business',
            name: '사업단관리',
            redirect: '/system/business/page',
            children: [
              {        
                path: '/system/business/page',
                name: '사업단조회',
                beforeEnter: onlyAuth,
                component: () => import('@/views/system/business/Page'),
              },
              {        
                path: '/system/business/create',
                name: '사업단생성',
                beforeEnter: onlyAuth,
                component: () => import('@/views/system/business/Create'),
              },
              {        
                path: '/system/business/modify',
                name: '사업단수정',
                beforeEnter: onlyAuth,
                component: () => import('@/views/system/business/Modify'),
              },
              {
                path: '/system/org-user/page',
                name: '관리기관주소록조회',
                beforeEnter: onlyAuth,
                component: () => import('@/views/system/org-user/Page'),
              },
            ]
          },
          {
            path: '/system/menu/page',
            name: '메뉴관리',
            beforeEnter: onlyAuth,
            component: () => import('@/views/system/menu/Page'),
          },
          {
            path: '/system/privacy/page',
            name: '시스템설정',
            beforeEnter: onlyAuth,
            component: () => import('@/views/system/privacy/Page'),
          },
        ],
      },
    ],
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404'),
      },
      {
        path: '500',
        name: 'Page500',
        component: () => import('@/views/pages/Page500'),
      },
      {
        path: 'login',
        name: 'Login',
        beforeEnter: onlyNotAuth,
        component: () => import('@/views/pages/Login'),
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/views/pages/Register'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
