import {
    requestSubjectPage,
    requestSubjectUpdate,
    requestSubjectInsert,
    requestSubjectDuplicateMobileCheck,
    requestSubjectFileUpload,
    requestSubjectsInsert,
    requestDubSubjectsExcelDownload,
    requestSubjectUploadSampleDownload
} from "../apis/subject_api";

import router from "@/router";

export const subject = {
    nemespaced: true,
    
    state: () => ({
        subjectItems: [],
        subjectTotalCount: 0,
        uploadSubjectList: []
    }),
    getters: {
        getSubjectItems(state){
            return state.subjectItems
        },
        getSubjectTotalCount(state){
            return state.subjectTotalCount
        },
        getSubjectList(state){
            return state.subjectList
        },
        getUploadSubjectList(state){
            return state.uploadSubjectList
        }
    },

    mutations: {
        REQUEST_SUBJECT_PAGE(state,data){
            state.subjectItems = data.data
            state.subjectTotalCount = data.totalCount
        },
        REQUEST_SUBJECT_PAGE_INIT(state){
            state.subjectItems = []
            state.subjectTotalCount = 0
        },
        REQUEST_SUBJECT_FILE_UPLOAD(state,data){
            state.uploadSubjectList = data.data
        }
    },

    actions: {
        async REQUEST_SUBJECT_PAGE(context,parameter) {
            context.commit('START_LOADING');
            try{
                const response = await requestSubjectPage(parameter.searchSubjectVO);
                if(response.status === 200){
                    context.commit('REQUEST_SUBJECT_PAGE',response.data);
                }
            }catch(e){
                console.log(e.response.data.resultMsg)
            }
            context.commit('END_LOADING');
        },
        async REQUEST_SUBJECT_UPDATE(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestSubjectUpdate(parameter.searchSubjectVO);
                if(response.status === 201){
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
        },
        async REQUEST_SUBJECT_INSERT(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestSubjectInsert(parameter.searchSubjectVO);
                if(response.status === 201){
                    parameter.context.complete();
                    alert("대상자정보 등록을 성공하였습니다.")
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
        },
        async REQUEST_SUBJECT_DUPLICATE_MOBILE_CHECK(context,parameter){
            context.commit('START_LOADING');
            const param = {}
            try{
                const response = await requestSubjectDuplicateMobileCheck(parameter.searchSubjectVO);
                if(response.status === 200){
                    param.data = response.data
                }
            }catch(e){
                console.log(e.response.data.resultMsg)
            }
            context.commit('END_LOADING');
            return param
        },
        async REQUEST_SUBJECT_FILE_UPLOAD(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestSubjectFileUpload(parameter);
                if(response.status === 200){
                    context.commit('REQUEST_SUBJECT_FILE_UPLOAD',response.data);
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
        },
        async REQUEST_SUBJECTS_INSERT(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestSubjectsInsert(parameter.subjectList);
                if(response.status === 201){
                    alert("대상자 등록을 성공하였습니다.")
                    parameter.context.complete()
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
        },
        async REQUEST_DUB_SUBJECTS_EXCEL_DOWNLOAD(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestDubSubjectsExcelDownload(parameter.subjectList);
                if(response.status === 200){
                    const url = URL.createObjectURL(new Blob([response.data], {
                        type: 'application/vnd.ms-excel; charset=euc-kr'
                      }))
                      const link = document.createElement('a')
                      link.href = url
                      link.setAttribute('download', "중복대상자_list.xlsx")
                      document.body.appendChild(link)
                      link.click()
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
        },
        async REQUEST_SUBJECT_UPLOAD_SAMPLE_DOWNLOAD(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestSubjectUploadSampleDownload(parameter.searchFileManagerVO);
                if(response.status === 200){
                    const url = URL.createObjectURL(new Blob([response.data], {
                        type: 'application/vnd.ms-excel; charset=euc-kr'
                      }))
                      const link = document.createElement('a')
                      link.href = url
                      link.setAttribute('download', "대상자_업로드_양식.xlsx")
                      document.body.appendChild(link)
                      link.click()
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
        },
    }

}