import {
    requestOrgPage,
    requestOrgManageUpdate,
    requestOrgSelect
} from "../apis/org_api";

import router from "@/router";

export const org = {
    nemespaced: true,
    
    state: () => ({
        orgTotalCount: 0,
        orgList: [],
        org: {}
    }),
    getters: {
        getOrgTotalCount(state){
            return state.orgTotalCount
        },
        getOrgList(state){
            return state.orgList
        },
        getOrg(state){
            return state.org
        }
    },

    mutations: {
        REQUEST_ORG_SELECT(state,data){
            state.org = data.data
        },
        REQUEST_ORG_PAGE(state,data){
            state.orgList = data.data
            state.orgTotalCount = data.totalCount
        },
        REQUEST_ORG_SELECT_INIT(state){
            state.org = {}
        },
        REQUEST_ORG_PAGE_INIT(state){
            state.orgList = []
            state.orgTotalCount = 0
        },
    },

    actions: {
        async REQUEST_ORG_SELECT(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestOrgSelect(parameter.searchOrgVO);
                if(response.status === 200){
                    context.commit('REQUEST_ORG_SELECT',response.data);
                }
            }catch(e){
                console.log(e.response.data.resultMsg)
            }
            context.commit('END_LOADING');

        },
        async REQUEST_ORG_PAGE(context,parameter) {
            context.commit('START_LOADING');
            try{
                const response = await requestOrgPage(parameter.searchOrgVO);
                if(response.status === 200){
                    context.commit('REQUEST_ORG_PAGE',response.data);
                }
            }catch(e){
                console.log(e.response.data.resultMsg)
            }
            context.commit('END_LOADING');
        },
        async REQUEST_ORG_MANAGE_UPDATE(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestOrgManageUpdate(parameter.searchOrgVO);
                if(response.status === 201){
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
        },
    }

}