import axios from 'axios'

function requestMenuForBusinessCreate(){
    return axios.post(`${process.env.VUE_APP_BASEURL}/system/business/menu/select`)
}
function requestBusinessMenuInsert(parameter) {
    return axios.post(`${process.env.VUE_APP_BASEURL}/system/business/menu/insert`, parameter)
}
function requestMenuPage(){
    return axios.post(`${process.env.VUE_APP_BASEURL}/system/menu/page`)
}
function requestMenuInsert(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/system/menu/insert`, parameter)
}
function requestMenuUpdate(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/system/menu/update`, parameter)
}
function requestMenuDelete(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/system/menu/delete`, parameter)
}
function requestAuthMenuList(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/system/auth/menu/list`, parameter)
}
function requestAuthMenuUpdate(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/system/auth/menu/update`, parameter)
}

export {
    requestMenuForBusinessCreate,
    requestBusinessMenuInsert,
    requestMenuPage,
    requestMenuInsert,
    requestMenuUpdate,
    requestMenuDelete,
    requestAuthMenuList,
    requestAuthMenuUpdate,
}