import {
    requestInstPage,
    requestInstSelect,
    requestInstExcelDownload,
    requestInstUpdate
} from "../apis/inst_api";

import router from "@/router";

const pageType = 'ing'

export const inst_ing = {
    nemespaced: true,
    
    state: () => ({
        instIngStateCd:'STE002', //진행코드

        instIngItems:[],
        instIngTotalCount:0,
        instIngItem:{},
    }),

    getters: {
        getInstIngStateCd(state){
            return state.instIngStateCd
        },
        getInstIngItems(state){
            return state.instIngItems
        },
        getInstIngTotalCount(state){
            return state.instIngTotalCount
        },
        getInstIngItem(state){
            return state.instIngItem
        },
    },

    mutations: {
        REQUEST_INST_ING_PAGE(state,data){
            state.instIngItems = data.data
            state.instIngTotalCount = data.totalCount
        },
        REQUEST_INST_ING_PAGE_INIT(state){
            state.instIngItems = []
            state.instIngTotalCount = 0
        },
        REQUEST_INST_ING_SELECT_INIT(state){
            state.instIngItem = {}
        },
        REQUEST_INST_ING_SELECT(state,data){
            state.instIngItem = data.data
        },
    },

    actions: {
        async REQUEST_INST_ING_PAGE(context,parameter) {
            context.commit('START_LOADING');
            try{
                
                const response = await requestInstPage(parameter.searchInst,pageType);
                if(response.status === 200){
                    context.commit('REQUEST_INST_ING_PAGE',response.data);
                }
            }catch(e){
                console.log(e.response.data.resultMsg)
            }
            context.commit('END_LOADING');
        },
        async REQUEST_INST_ING_SELECT(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestInstSelect(parameter.searchInst,pageType);
                if(response.status === 200){
                    context.commit('REQUEST_INST_ING_SELECT',response.data);
                }
            }catch(e){
                console.log(e.response.data.resultMsg)
            }
            context.commit('END_LOADING');
        },
        async REQUEST_INST_ING_EXCEL_DOWNLOAD(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestInstExcelDownload(parameter.searchInst,pageType);
                if(response.status === 200){
                    const url = URL.createObjectURL(new Blob([response.data], {
                        type: 'application/vnd.ms-excel; charset=euc-kr'
                      }))
                      const link = document.createElement('a')
                      link.href = url
                      link.setAttribute('download', "설치진행list_"+ parameter.date +".xlsx")
                      document.body.appendChild(link)
                      link.click()
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
        },
        async REQUEST_INST_ING_UPDATE(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestInstUpdate(parameter.searchInst,pageType);
                if(response.status === 201){
                    alert("취소되었습니다.")
                    parameter.context.complete(parameter)
                }
            }catch(e){
                console.log(e.response.data.resultMsg)
            }
            context.commit('END_LOADING');
        },
    },

}