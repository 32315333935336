import axios from 'axios'
import { sendMultipartFilePost } from '@/utils/multipart-helper'

function requestNoticeInsert(parameter) {
    const formData = new FormData()
    for ( var key in parameter.noticeVO ) {
        if(parameter.noticeVO[key]!==null){
            if(key == 'businessList'){
                parameter.noticeVO[key].forEach((value, index, array)=>{
                    formData.append("businessList["+index+"].businessSchemaName", value.value)
                  })
            }else{
                formData.append(key, parameter.noticeVO[key])
            }
        }
    }
    if(parameter.files!=null){
        for(let i=0;i<parameter.files.length;i++)
            formData.append('file'+i, parameter.files[i])
    }

    return sendMultipartFilePost(`${process.env.VUE_APP_BASEURL}/board/notice/insert`, formData)
}
function requestNoticePage(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/board/notice/page`, parameter)
}
function requestNoticeSelect(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/board/notice/select`, parameter)
}
function requestNoticeFileDownload(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/board/notice/file/download`, parameter, {responseType: 'blob'})
}
function requestNoticeUpdate(parameter){
    const formData = new FormData()
    for ( var key in parameter.noticeVO ) {
        if(parameter.noticeVO[key]!==null){
            if(key == 'businessList'){
                parameter.noticeVO[key].forEach((value, index, array)=>{
                    formData.append("businessList["+index+"].businessSchemaName", value.value)
                  })
            }else{
                formData.append(key, parameter.noticeVO[key])
            }
        }
    }
    const noneDeleteFileList = []
    for(let i=0;i<parameter.noticeVO.fileList.length;i++){
        if(!parameter.noticeVO.fileList[i].loadFile){
            formData.append('fileList'+i, parameter.noticeVO.fileList[i])
        }else{
            noneDeleteFileList.push(parameter.noticeVO.fileList[i].fileManagerNo)
        }
    }
    
    formData.append('noneDeleteFileList', noneDeleteFileList)
    return sendMultipartFilePost(`${process.env.VUE_APP_BASEURL}/board/notice/update`, formData)
}
function requestNoticeDelete(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/board/notice/delete`,parameter)
}
function requestNoticeHitsUp(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/board/notice/hits-update`,parameter)
}

export {
    requestNoticeInsert,
    requestNoticePage,
    requestNoticeSelect,
    requestNoticeFileDownload,
    requestNoticeUpdate,
    requestNoticeDelete,
    requestNoticeHitsUp
}