import { createStore } from 'vuex'
import { common } from './common'
import { user } from './user'
import { business } from './business'
import { menu } from './menu'
import { inst_set } from './inst_set'
import { inst_ing } from './inst_ing'
import { inst_finish } from './inst_finish'
import { inst_cancel } from './inst_cancel'
import { subject } from './subject'
import { account } from './account'
import { as_set } from './as_set'
import { as_ing } from './as_ing'
import { as_finish } from './as_finish'
import { as_cancel } from './as_cancel'
import { as_static } from './as_static'
import { as_staticcomfirm } from './as_staticcomfirm'
import { notice } from './notice'
import { org } from './org'

export default createStore({
  state: {
    sidebarVisible: '',
    sidebarUnfoldable: true,
  },
  getters: {
    
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
  },
  actions: {},
  modules: {
    common
    , user
    , business
    , menu
    , inst_set
    , inst_ing
    , inst_finish
    , inst_cancel
    , as_set
    , as_ing
    , as_finish
    , as_cancel
    , as_static
    , as_staticcomfirm
    , subject
    , account
    , notice
    , org
  },
})
