import axios from 'axios'

function requestAsPage(parameter,page){
    return axios.post(`${process.env.VUE_APP_BASEURL}/as/${page}/page`,parameter)
}
function requestAsStateUpdate(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/as/set/state/update`, parameter)
}
function requestAsAssignCancel(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/as/set/assign-cancel/update`, parameter)
}
function requestAsListStateUpdate(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/as/set/list-state/update`, parameter)
}
function requestAsSelect(parameter,page){
    return axios.post(`${process.env.VUE_APP_BASEURL}/as/${page}/select`,parameter)
}
function requestAsUpdate(parameter,page){
    return axios.post(`${process.env.VUE_APP_BASEURL}/as/${page}/update`,parameter)
}
function requestAsExcelDownload(parameter,page){
    return  axios.post(`${process.env.VUE_APP_BASEURL}/as/${page}/excel/download`, parameter, {responseType: 'blob'})  
} 
function requestAsCancelExcelDownload(parameter,page){
    return  axios.post(`${process.env.VUE_APP_BASEURL}/as/cancel-excel/download`, parameter, {responseType: 'blob'})  
}
function requestAsInsert(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/subject/as/insert`, parameter)
}
function requestAssInsert(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/subject/as/list/insert`, parameter)
}
function requestAsStatisticPage(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/current/statistic/as/page`,parameter)
}
function requestPeriodOverPage(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/current/period-over/page`,parameter)
}
function requestAsStatisticExcelDownload(parameter){
    return  axios.post(`${process.env.VUE_APP_BASEURL}/current/statistic/as/excel/download`, parameter, {responseType: 'blob'})  
} 
function requestAsPeriodOverExcelDownload(parameter){
    return  axios.post(`${process.env.VUE_APP_BASEURL}/current/period-over/excel/download`, parameter, {responseType: 'blob'})  
} 
function requestBusinessAsCodeSelect(){
    return axios.post(`${process.env.VUE_APP_BASEURL}/system/business-code/as/select`)
}
function requestBusinessAsCodeInsert(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/system/business-code/as/insert`,parameter)
}
function requestBusinessAsCodeUpdate(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/system/business-code/as/update`,parameter)
}
function requestBusinessAsCodeDelete(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/system/business-code/as/delete`,parameter)
}


function requestAsInitInspector(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/current/asChecklist/init-inspector/update`,parameter)
}

function requestAsStaticList(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/as/static/list`,parameter)
}

function requestAsStaticExcelDownload(parameter,page){
    return  axios.post(`${process.env.VUE_APP_BASEURL}/as/static/excel/download`, parameter, {responseType: 'blob'})  
} 


function requestAsStaticDetailPage(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/as/static/detail/page`,parameter)
}

function requestAsStaticDetailExcelDownload(parameter,page){
    return  axios.post(`${process.env.VUE_APP_BASEURL}/as/static/detail/excel/download`, parameter, {responseType: 'blob'})  
} 



function requestAsStaticComfirmList(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/as/staticComfirm/list`,parameter)
}

function requestAsStaticComfirmExcelDownload(parameter,page){
    return  axios.post(`${process.env.VUE_APP_BASEURL}/as/staticComfirm/excel/download`, parameter, {responseType: 'blob'})  
}


function requestAsStaticComfirmDetailPage(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/as/staticComfirm/detail/page`,parameter)
}

function requestAsStaticComfirmDetailExcelDownload(parameter,page){
    return  axios.post(`${process.env.VUE_APP_BASEURL}/as/staticComfirm/detail/excel/download`, parameter, {responseType: 'blob'})  
} 


export {
    requestAsPage,
    requestAsStateUpdate,
    requestAsAssignCancel,
    requestAsListStateUpdate,
    requestAsSelect,
    requestAsUpdate,
    requestAsExcelDownload,
    requestAsCancelExcelDownload,
    requestAsInsert,
    requestAssInsert,
    requestAsStatisticPage,
    requestPeriodOverPage,
    requestAsStatisticExcelDownload,
    requestAsPeriodOverExcelDownload,
    requestBusinessAsCodeSelect,
    requestBusinessAsCodeInsert,
    requestBusinessAsCodeUpdate,
    requestBusinessAsCodeDelete,
    requestAsInitInspector,
    requestAsStaticList,
    requestAsStaticExcelDownload,
    requestAsStaticDetailPage,
    requestAsStaticDetailExcelDownload,
    requestAsStaticComfirmDetailPage,
    requestAsStaticComfirmDetailExcelDownload,

    requestAsStaticComfirmList,
    requestAsStaticComfirmExcelDownload,
}