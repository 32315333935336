import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import mitt from 'mitt'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsExample from '@/components/DocsExample'
import { VueSignaturePad } from 'vue-signature-pad'

import CoreuiVueCharts from '@coreui/vue-chartjs'
import Datepicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

const emitter = mitt();

const app = createApp(App)




app.config.globalProperties.$store = store
app.config.globalProperties.emitter = emitter
app.config.globalProperties.$axios = axios
axios.defaults.withCredentials = false

app.component('VueSignaturePad', VueSignaturePad)
app.component('Datepicker', Datepicker)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(CoreuiVueCharts)
app.use(mitt)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('DocsExample', DocsExample)

app.mount('#app')
