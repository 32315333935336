import {
    requestInstPage,
    requestInstStateUpdate,
    requestInstAssignCancel,
    requestInstListStateUpdate,
    requestInstInsert,
    requestInstsInsert,
    requestInstSelect,
    requestInstExcelDownload,
    requestInstUpdate,
} from "../apis/inst_api";

import router from "@/router";

const pageType = 'set'

export const inst_set = {
    nemespaced: true,
    
    state: () => ({
        instSetStateCd:'STE001', //진행코드

        instSetItems:[],
        instSetTotalCount:0,
        instSetItem:{},
    }),

    getters: {
        getInstSetStateCd(state){
            return state.instSetStateCd
        },
        getInstSetItems(state){
            return state.instSetItems
        },
        getInstSetTotalCount(state){
            return state.instSetTotalCount
        },
        getInstSetItem(state){
            return state.instSetItem
        },
    },

    mutations: {
        REQUEST_INST_SET_PAGE(state,data){
            state.instSetItems = data.data
            state.instSetTotalCount = data.totalCount
        },
        REQUEST_INST_SET_PAGE_INIT(state){
            state.instSetItems = []
            state.instSetTotalCount = 0
        },
        REQUEST_INST_SET_SELECT(state,data){
            state.instSetItem = data.data
        },
        REQUEST_INST_SET_SELECT_INIT(state){
            state.instSetItem = {}
        },
    },

    actions: {
        async REQUEST_INST_SET_PAGE(context,parameter) {
            context.commit('START_LOADING');
            try{
                const response = await requestInstPage(parameter.searchInst,pageType);
                if(response.status === 200){
                    context.commit('REQUEST_INST_SET_PAGE',response.data);
                }
            }catch(e){
                console.log(e.response.data.resultMsg)
            }
            context.commit('END_LOADING');
        },
        async REQUEST_INST_ASSIGN(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestInstStateUpdate(parameter.searchInstVO);
                if(response.status === 201){
                    parameter.context.complete();
                    alert("기사배정을 성공하였습니다.")
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
        },
        async REQUEST_INST_ASSIGN_CANCEL(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestInstAssignCancel(parameter.searchInstVO);
                if(response.status === 200){
                    parameter.context.complete();
                    alert("기사배정을 제거하였습니다.")
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
        },
        async REQUEST_INST_BATCH_ASSIGN(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestInstListStateUpdate(parameter.searchInstList);
                if(response.status === 201){
                    parameter.context.complete();
                    alert("기사배정을 성공하였습니다.")
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
        },
        async REQUEST_INST_INSERT(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestInstInsert(parameter.searchInstVO);
                if(response.status === 201){
                    alert("예약등록을 성공하였습니다.")
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
        },
        async REQUEST_INSTS_INSERT(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestInstsInsert(parameter.searchInstList);
                if(response.status === 201){
                    alert("예약등록을 성공하였습니다.")
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
        },
        async REQUEST_INST_SET_SELECT(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestInstSelect(parameter.searchInst,pageType);
                if(response.status === 200){
                    context.commit('REQUEST_INST_SET_SELECT',response.data);
                }
            }catch(e){
                console.log(e.response.data.resultMsg)
            }
            context.commit('END_LOADING');
        },
        async REQUEST_INST_SET_EXCEL_DOWNLOAD(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestInstExcelDownload(parameter.searchInst,pageType);
                if(response.status === 200){
                    const url = URL.createObjectURL(new Blob([response.data], {
                        type: 'application/vnd.ms-excel; charset=euc-kr'
                      }))
                      const link = document.createElement('a')
                      link.href = url
                      link.setAttribute('download', "설치예약list_"+ parameter.date +".xlsx")
                      document.body.appendChild(link)
                      link.click()
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
        },
        async REQUEST_INST_SET_UPDATE(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestInstUpdate(parameter.searchInst,pageType);
                if(response.status === 201){
                    alert("취소되었습니다.")
                    parameter.context.complete(parameter)
                }
            }catch(e){
                console.log(e.response.data.resultMsg)
            }
            context.commit('END_LOADING');
        },
    },

}