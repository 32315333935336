import axios from 'axios'

function requestBusinessPage(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/system/business/page`, parameter)
}
function requestBusinessSchemaDuplicateCheck(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/system/business/schema/select`,parameter)
}
function requestBusinessInsert(parameter) {
    return axios.post(`${process.env.VUE_APP_BASEURL}/system/business/insert`, parameter)
}
function requestBusinessSelect(parameter) {
    return axios.post(`${process.env.VUE_APP_BASEURL}/system/business/select`, parameter)
}
function requestBusinessUpdate(parameter) {
    return axios.post(`${process.env.VUE_APP_BASEURL}/system/business/update`, parameter)
}
function requestBusinessPrivacyUpdate(parameter) {
    return axios.post(`${process.env.VUE_APP_BASEURL}/system/business/privacy/update`, parameter)
}

export {
    requestBusinessPage,
    requestBusinessSchemaDuplicateCheck,
    requestBusinessInsert,
    requestBusinessSelect,
    requestBusinessUpdate,
    requestBusinessPrivacyUpdate
}