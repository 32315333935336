<template>
  <strong class="welcome-text"> {{getUserAccountName}} {{getUser.userName}}님 안녕하세요 </strong>
  <CDropdown variant="nav-item">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false" :href="diabled">
      <CAvatar :src="user" size="md" />
    </CDropdownToggle>
    <CDropdownMenu class="pt-0">
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        Settings
      </CDropdownHeader>
      <CDropdownItem @click="userModify" > <CIcon icon="cil-user"/> 사용자정보 </CDropdownItem>
      <!-- <CDropdownItem> <CIcon icon="cil-settings" /> Settings </CDropdownItem> -->
      <CDropdownDivider />
      <CDropdownItem @click="logout"> <CIcon icon="cil-lock-locked" /> 로그아웃 </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>

  <UserModifyModal/>

</template>

<script>
import { mapGetters } from 'vuex'
import UserModifyModal from '@/components/UserModifyModal'

import user from '@/assets/images/user.png'
export default {
  name: 'AppHeaderDropdownAccnt',
  components:{UserModifyModal},
  setup() {
    return {
      user: user,
    }
  },
  computed: {
    ...mapGetters(['getUser','getUserAccountName','getUserBusinessItems','getUserAccountItems']),
  },
  methods:{
    async userModify(){
      var loginUser = {}
      const parameter = {
        context:this,
        searchUserVO: {
            userId:this.getUser.userId,
        }
      }
      await this.$store.dispatch('REQUEST_USERINFO_SELECT', parameter).then((data) => {
        loginUser = data.data
      })

      await this.$store.dispatch('REQUEST_USERINFO_BUSINESS_LIST', parameter).then((data) => {
        loginUser.userBusinessMappingList = this.getUserBusinessItems
        loginUser.userAccountList = this.getUserAccountItems
        this.emitter.emit('loginUserModify', loginUser)
      })
    },
    logout(){
      this.$store.dispatch('REQUEST_LOGOUT')
    }
  }
}
</script>
