const setTokenInLocalStorage = tokenInfo => {
  localStorage.setItem("access_token", "Bearer " + tokenInfo.token);
  localStorage.setItem("refresh_token", tokenInfo.refreshToken);

  localStorage.setItem("userId", tokenInfo.userId);
  localStorage.setItem("userName", tokenInfo.userName);
  localStorage.setItem("userMobile", tokenInfo.userMobile);
  localStorage.setItem("userAuthorityId", tokenInfo.userAuthorityId);

  const businessList = tokenInfo.userBusinessMappingList
  const userBusinessMappingList = []
  for(let i=0; i<businessList.length; i++) {
    userBusinessMappingList.push({
      label: businessList[i].businessName,
      value: businessList[i].businessSchemaName,
      });
  }
  localStorage.setItem("userBusinessMappingList", JSON.stringify(userBusinessMappingList));
  if(localStorage.getItem('userBusinessSchema')=== null || localStorage.getItem('userBusinessSchema')=== undefined){
    localStorage.setItem("userBusinessSchema", userBusinessMappingList[0].value);
    localStorage.setItem("userBusinessName", userBusinessMappingList[0].label);
  }else{
    let businessExistFlag = false;
    for(let i=0;i<userBusinessMappingList.length;i++){
      if(userBusinessMappingList[i].value === localStorage.getItem('userBusinessSchema')){
        businessExistFlag = true;
      }
    }
    if(!businessExistFlag){
      localStorage.setItem("userBusinessSchema", userBusinessMappingList[0].value);
      localStorage.setItem("userBusinessName", userBusinessMappingList[0].label);
    }
  }
  debugger
  localStorage.setItem("privacyYn", businessList[0].businessPrivacyYn);
  localStorage.setItem("businessAsSelfUpdateFlagYn", businessList[0].businessAsSelfUpdateFlagYn);
  localStorage.setItem("businessPdfSelfTransFlagYn", businessList[0].businessPdfSelfTransFlagYn);
  localStorage.setItem("businessPdfSnsSelfTransFlagYn", businessList[0].businessPdfSnsSelfTransFlagYn);

  
  localStorage.setItem("businessPdfSelfTransPeriod", businessList[0].businessPdfSelfTransPeriod);
  localStorage.setItem("businessPdfSnsSelfTransPeriod", businessList[0].businessPdfSnsSelfTransPeriod);
  
  
  
};


// 2020-05-22, DB 설계 내용과 동일하게 field명 변경
const deleteTokenInLocalStorage = () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("userId");
  localStorage.removeItem("userName");
  localStorage.removeItem("userMobile");
  localStorage.removeItem("userAuthorityId");
  localStorage.removeItem("userBusinessMappingList");
  localStorage.removeItem("userBusinessSchema");
  localStorage.removeItem("userBusinessName");
  localStorage.removeItem("userAccountId");
  localStorage.removeItem("userAccountName");
  localStorage.removeItem("privacyYn");
  localStorage.removeItem("businessAsSelfUpdateFlagYn");
  localStorage.removeItem("businessPdfSelfTransFlagYn");
  localStorage.removeItem('businessPdfSnsSelfTransFlagYn');
  localStorage.removeItem("businessPdfSelfTransPeriod");
  localStorage.removeItem("businessPdfSnsSelfTransPeriod");
  
  
  
  
};

const setBusinessInfoLocalStorage = business => {
  localStorage.setItem("userBusinessSchema", business.value);
  localStorage.setItem("userBusinessName", business.label);
};

const setAccountInfoLocalStorage = data => {
  if(data.accountInfo!=null){
    localStorage.setItem("userAccountId", data.accountInfo.accountId)
    localStorage.setItem("userAccountName", data.accountInfo.accountName)

      
  }
  if(data.business!=null){
  localStorage.setItem("privacyYn", data.business.businessPrivacyYn);
  localStorage.setItem("businessAsSelfUpdateFlagYn", data.business.businessAsSelfUpdateFlagYn);
  localStorage.setItem("businessPdfSelfTransFlagYn", data.business.businessPdfSelfTransFlagYn);
  localStorage.setItem("businessPdfSnsSelfTransFlagYn", data.business.businessPdfSnsSelfTransFlagYn);

  
  localStorage.setItem("businessPdfSelfTransPeriod", data.business.businessPdfSelfTransPeriod);
  localStorage.setItem("businessPdfSnsSelfTransPeriod", data.business.businessPdfSnsSelfTransPeriod);

      
  }
  
  
}

export {
  deleteTokenInLocalStorage,
  setTokenInLocalStorage,
  setBusinessInfoLocalStorage,
  setAccountInfoLocalStorage
};
