import {
    requestMenuForBusinessCreate,
    requestBusinessMenuInsert,
    requestMenuPage,
    requestMenuInsert,
    requestMenuUpdate,
    requestMenuDelete,
    requestAuthMenuList,
    requestAuthMenuUpdate,
} from "../apis/menu_api";

import router from "@/router";
import { getStyle, hexToRgba } from '@coreui/utils/src'

export const menu = {
    namaspaced: true,

    state: () => ({
        menuItems:[],
    }),

    getters: {
        getMenuItems(state){
            return state.menuItems
        },
    },

    mutations: {
        REQUEST_MENU_FOR_BUSINESS_CREATE(state,data){
            state.menuItems = data
        },
        REQUEST_MENU_PAGE(state,data){
            state.menuItems = data
        },
        REQUEST_AUTH_MENU(state,data){
            state.menuItems = data
        },
    },

    actions: {
        async REQUEST_MENU_FOR_BUSINESS_CREATE(context){
            context.commit('START_LOADING');
            try{
                const response = await requestMenuForBusinessCreate();
                if(response.status === 200){
                    context.commit('REQUEST_MENU_FOR_BUSINESS_CREATE',response.data);
                }
            }catch(e){
                console.log(e.response.data.resultMsg)
            }
            context.commit('END_LOADING');
        },
        async REQUEST_BUSINESS_MENU_INSERT(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestBusinessMenuInsert(parameter.menuList);
                if(response.status === 201){
                    alert("사업단 등록이 완료되었습니다!")
                    parameter.context.complete();
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
    
        },
        async REQUEST_MENU_PAGE(context){
            context.commit('START_LOADING');
            try{
                const response = await requestMenuPage();
                if(response.status === 200){
                    context.commit('REQUEST_MENU_PAGE',response.data);
                }
            }catch(e){
                console.log(e.response.data.resultMsg)
            }
            context.commit('END_LOADING');
        },
        async REQUEST_MENU_INSERT(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestMenuInsert(parameter.searchMenuVO);
                if(response.status === 201){
                    parameter.context.complete();
                    alert("메뉴가 등록되었습니다")
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
    
        },
        async REQUEST_MENU_UPDATE(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestMenuUpdate(parameter.searchMenuVO);
                if(response.status === 201){
                    parameter.context.complete();
                    alert("메뉴정보 수정을 성공하였습니다.")
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
        },
        async REQUEST_MENU_DELETE(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestMenuDelete(parameter.searchMenuVO);
                if(response.status === 200){
                    parameter.context.complete();
                    alert("메뉴를 삭제하였습니다.")
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
        },
        async REQUEST_AUTH_MENU(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestAuthMenuList(parameter);
                if(response.status === 200){
                    context.commit('REQUEST_AUTH_MENU',response.data);
                }
            }catch(e){
                console.log(e.response.data.resultMsg)
            }
            context.commit('END_LOADING');
        },
        async REQUEST_AUTH_MENU_UPDATE(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestAuthMenuUpdate(parameter.searchMenuVO);
                if(response.status === 201){
                    alert("권한을 수정하였습니다.")
                }
            }catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
        },
    }
}