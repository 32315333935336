import {
    requestInstPage,
    requestInstSelect,
    requestCheckListPage,
    requestInstStatisticPage,
    requestInstBatchDownload,
    requestInstExcelDownload,
    requestInstStatisticExcelDownload,
    requestInstPdfFinishTrans,
    requestInstUpdate,
    requestInstEngineerSelect,
    requestInstCompletePdfFormSelect,
    requestCompletePdfInsert,
    requestCompletePdfUpdate,
    requestCompleteListPage,
    requestCompleteListSelect,
    requestCompleteListDelete,
    requestInstInitInspector,
    requestInstInitInspectors,
    requestInstFinishFileDownload
} from "../apis/inst_api";
import{
    requestUrlSmsSend
} from "../apis/common_api";

import router from "@/router";
import { getStyle, hexToRgba } from '@coreui/utils/src'

const pageType = 'finish'

export const inst_finish = {
    nemespaced: true,
    
    state: () => ({
        instFinishStateCd:'STE003', //진행코드

        instFinishItems:[],
        instFinishTotalCount:0,
        instFinishItem:{},
        checkListItems:[],
        checkListTotalCount:0,

        instStatisticItems:[],
        instStatisticTotalCount:0,

        instEngineerItems:[],
        completePdfSetsForm:null,
        
        completeListItems:[],
        completeListTotalCount:0,
        completeListItem:{},

        instStatisticComfirmGraph:{
            labels:[],
            datasets:[]
        },
    }),

    getters: {
        getInstFinishStateCd(state){
            return state.instFinishStateCd
        },
        getInstFinishItems(state){
            return state.instFinishItems
        },
        getInstFinishTotalCount(state){
            return state.instFinishTotalCount
        },
        getInstFinishItem(state){
            return state.instFinishItem
        },
        getCheckListItems(state){
            return state.checkListItems
        },
        getCheckListTotalCount(state){
            return state.checkListTotalCount
        },
        getInstStatisticItems(state){
            return state.instStatisticItems
        },
        getInstStatisticComfirmGraph(state){
            return state.instStatisticComfirmGraph
        },
        getInstStatisticTotalCount(state){
            return state.instStatisticTotalCount
        },
        getInstEngineerItems(state){
            return state.instEngineerItems
        },
        getCompletePdfSetsForm(state){
            return state.completePdfSetsForm
        },
        getCompleteListItems(state){
            return state.completeListItems
        },
        getCompleteListTotalCount(state){
            return state.completeListTotalCount
        },
        getCompleteList(state){
            return state.completeListItem
        },
    },

    mutations: {
        REQUEST_INST_FINISH_PAGE(state,data){
            state.instFinishItems = data.data
            state.instFinishTotalCount = data.totalCount
        },
        REQUEST_INST_FINISH_PAGE_INIT(state){
            state.instFinishItems = []
            state.instFinishTotalCount = 0
        },
        REQUEST_INST_FINISH_SELECT_INIT(state){
            state.instFinishItem = {}
        },
        REQUEST_INST_FINISH_SELECT(state,data){
            state.instFinishItem = data.data
        },
        REQUEST_CHECKLIST_PAGE(state,data){
            state.checkListItems = data.data
            state.checkListTotalCount = data.totalCount
        },
        REQUEST_CHECKLIST_PAGE_INIT(state,data){
            state.checkListItems = []
            state.checkListTotalCount = 0
        },
        REQUEST_INST_STATISTIC_PAGE(state,data){
            state.instStatisticItems = data.data
            state.instStatisticTotalCount = data.totalCount
        },
        REQUEST_INST_STATISTIC_COMFIRM_GRAPH(state,data){
            debugger
            const item = data.data
            const labels = []
            const graphDatas = []
            for(var i= item.length-1 ;-1<i;i--){
                labels.push(item[i].statisticDate);
                graphDatas.push(Number(item[i].instFinishStatistic));
            }
            
            state.instStatisticComfirmGraph.labels = labels
            state.instStatisticComfirmGraph.datasets.push(
                {
                    animation: false,
                    label: '설치 완료 통계',
                    backgroundColor: '#00B0F0',
                    borderColor: '#00B0F0',
                    pointHoverBackgroundColor: getStyle('--cui-success'),
                    borderWidth: 2,
                    borderDash: [2, 2],
                    data: graphDatas,
                  },
            )

        },
        REQUEST_INST_STATISTIC_PAGE_INIT(state){
            state.instStatisticItems = []
            state.instStatisticTotalCount = 0
            state.instStatisticComfirmGraph = {
                labels:[],
                datasets:[]
            }
        },
        REQUEST_INST_ENGINEER_SELECT(state,data){
            state.instEngineerItems = data.data
        },
        REQUEST_INST_ENGINEER_SELECT_INIT(state){
            state.instEngineerItems = []
        },
        REQUEST_INST_COMPLETEPDF_FORM_SELECT(state,data){
            state.completePdfSetsForm = data.data
        },
        REQUEST_INST_COMPLETELIST_PAGE(state,data){
            state.completeListItems = data.data
            state.completeListTotalCount = data.totalCount
        },
        REQUEST_INST_COMPLETELIST_PAGE_INIT(state){
            state.completeListItems = []
            state.completeListTotalCount = 0
        },
        REQUEST_INST_COMPLETELIST_SELECT(state,data){
            state.completeListItem = data.data
        }
    },

    actions: {
        async REQUEST_INST_FINISH_PAGE(context,parameter) {
            context.commit('START_LOADING');
            try{
                
                const response = await requestInstPage(parameter.searchInst,pageType);
                if(response.status === 200){
                    context.commit('REQUEST_INST_FINISH_PAGE',response.data);
                }
            }catch(e){
                console.log(e.response.data.resultMsg)
            }
            context.commit('END_LOADING');
        },
        async REQUEST_INST_FINISH_SELECT(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestInstSelect(parameter.searchInst,pageType);
                if(response.status === 200){
                    context.commit('REQUEST_INST_FINISH_SELECT',response.data);
                }
            }catch(e){
                console.log(e.response.data.resultMsg)
            }
            context.commit('END_LOADING');
        },
        async REQUEST_CHECKLIST_PAGE(context,parameter) {
            context.commit('START_LOADING');
            try{
                
                const response = await requestCheckListPage(parameter.searchInst);
                if(response.status === 200){
                    context.commit('REQUEST_CHECKLIST_PAGE',response.data);
                }
            }catch(e){
                console.log(e.response.data.resultMsg)
            }
            context.commit('END_LOADING');
        },
        async REQUEST_INST_STATISTIC_PAGE(context,parameter) {
            context.commit('START_LOADING');
            try{
                const response = await requestInstStatisticPage(parameter.searchInstHistory);
                if(response.status === 200){
                    context.commit('REQUEST_INST_STATISTIC_PAGE',response.data);
                    context.commit('REQUEST_INST_STATISTIC_COMFIRM_GRAPH',response.data);
                }
            }catch(e){
                console.log(e.response.data.resultMsg)
            }
            context.commit('END_LOADING');
        },
        async REQUEST_CHECKLIST_BATCH_DOWNLOAD(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestInstBatchDownload(parameter.searchInstList);
                if(response.status === 200){
                    const url = URL.createObjectURL(new Blob([response.data], {
                        type: 'application/zip; Content-Disposition; attachment;'
                      }))
                      const link = document.createElement('a')
                      link.href = url
                      link.setAttribute('download', "설치점검표_" + parameter.date + ".zip")
                      document.body.appendChild(link)
                      link.click()
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
            
        },
        async REQUEST_INST_FINISH_EXCEL_DOWNLOAD(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestInstExcelDownload(parameter.searchInst,pageType);
                if(response.status === 200){
                    const url = URL.createObjectURL(new Blob([response.data], {
                        type: 'application/vnd.ms-excel; charset=euc-kr'
                      }))
                      const link = document.createElement('a')
                      link.href = url
                      link.setAttribute('download', "설치완료list_"+ parameter.date +".xlsx")
                      document.body.appendChild(link)
                      link.click()
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
        },
        async REQUEST_INST_STATISTIC_EXCEL_DOWNLOAD(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestInstStatisticExcelDownload(parameter.searchInstHistory,pageType);
                if(response.status === 200){
                    const url = URL.createObjectURL(new Blob([response.data], {
                        type: 'application/vnd.ms-excel; charset=euc-kr'
                      }))
                      const link = document.createElement('a')
                      link.href = url
                      link.setAttribute('download', "설치통계현황_"+ parameter.date +".xlsx")
                      document.body.appendChild(link)
                      link.click()
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)    
            }
            context.commit('END_LOADING');
        },
        async REQUEST_INST_PDF_FINISH_TRANS(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestInstPdfFinishTrans(parameter.searchInstVO);
                if(response.status === 200){
                    
                    let asInstPdfFlagTxt = '';
                    if(Number(parameter.searchInstVO.instPdfDistributeFlag) === 1){
                        asInstPdfFlagTxt = '재설치 '
                      }
                      if(Number(parameter.searchInstVO.instPdfDistributeFlag) === 2){
                        asInstPdfFlagTxt = '변경설치 '
                      }
                      if(Number(parameter.searchInstVO.instPdfDistributeFlag) === 3){
                        asInstPdfFlagTxt = '신규설치 '
                      }

                    var msg = '[' + localStorage.getItem("userBusinessName") + ']\n'
                        + ' - 구분 : ' + asInstPdfFlagTxt + '설치점검표'
                        + '\n - 일자 : ' + parameter.searchInstVO.instStartFinishDate + ' ~ ' + parameter.searchInstVO.instEndFinishDate
                        + '\n - 지역 : ' + parameter.searchInstVO.addrSido + ' ' + parameter.searchInstVO.addrSigungu
                        + '\n - 센터명 : ' + parameter.searchInstVO.orgName
                        + '\n - 가구수 : ' + parameter.checkListTotalCount + '가구'

                    if(!confirm(msg + '\n위 내용으로 전송하시겠습니까?')){
                        context.commit('END_LOADING');
                        return
                    }
                    
                    const param = {
                        receiver: parameter.checkerMobile,
                        msg: msg 
                            + '\n하단 URL을 통해 점검표 최종 검수 후 서명 진행 부탁드립니다.'
                            + '\n※ 서명 완료 후에는 사진 재확인 및 서명 수정이 불가능하오니 신중하게 서명 부탁드리며, 필요시 서명 전 사진 다운로드해 주시길 바랍니다.'
                            + '\n\n[URL]'
                            + `\n${process.env.VUE_APP_BASEURL_MOBILE}/#/external/signatureList?value=`+ response.data.data
                    }

                    await requestUrlSmsSend(param)
                    prompt("해당 내용의 설치점검표가 전송되었습니다.",param.msg)
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
        },
        async REQUEST_INST_FINISH_UPDATE(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestInstUpdate(parameter.searchInst,pageType);
                if(response.status === 201){
                    alert("진행상태로 변경 되었습니다.")
                    parameter.context.complete(parameter)
                }
            }catch(e){
                console.log(e.response.data.resultMsg)
            }
            context.commit('END_LOADING');
        },
        async REQUEST_INST_ENGINEER_SELECT(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestInstEngineerSelect(parameter.searchInst);
                if(response.status === 200){
                    context.commit('REQUEST_INST_ENGINEER_SELECT',response.data)
                }
            }catch(e){
                console.log(e.response.data.resultMsg)
            }
            context.commit('END_LOADING');
        },
        async REQUEST_INST_COMPLETEPDF_FORM_SELECT(context){
            context.commit('START_LOADING');
            try{
                const response = await requestInstCompletePdfFormSelect();
                if(response.status === 200){
                    context.commit('REQUEST_INST_COMPLETEPDF_FORM_SELECT',response.data)
                }
            }catch(e){
                console.log(e.response.data.resultMsg)
            }
            context.commit('END_LOADING');
        },
        async REQUEST_INST_COMPLETE_PDF_INSERT(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestCompletePdfInsert(parameter.searchCompletePdf);
                if(response.status === 201){
                    alert("설치완료표가 작성되었습니다.")
                    parameter.context.complete();
                }
            }catch(e){
                console.log(e.response.data.resultMsg)
            }
            context.commit('END_LOADING');
        },
        async REQUEST_INST_COMPLETE_PDF_UPDATE(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestCompletePdfUpdate(parameter.searchCompletePdf);
                if(response.status === 201){
                    alert("설치완료표가 수정되었습니다.")
                    parameter.context.complete();
                }
            }catch(e){
                console.log(e.response.data.resultMsg)
            }
            context.commit('END_LOADING');
        },
        async REQUEST_INST_COMPLETELIST_PAGE(context,parameter) {
            context.commit('START_LOADING');
            try{
                const response = await requestCompleteListPage(parameter.searchCompletePdf);
                if(response.status === 200){
                    context.commit('REQUEST_INST_COMPLETELIST_PAGE',response.data);
                }
            }catch(e){
                console.log(e.response.data.resultMsg)
            }
            context.commit('END_LOADING');
        },
        async REQUEST_INST_COMPLETELIST_SELECT(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestCompleteListSelect(parameter.searchCompletePdf);
                if(response.status === 200){
                    context.commit('REQUEST_INST_COMPLETELIST_SELECT',response.data);
                }
            }catch(e){
                console.log(e.response.data.resultMsg)
            }
            context.commit('END_LOADING');
        },
        async REQUEST_INST_COMPLETELIST_PDF_TRANS(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestInstPdfFinishTrans(parameter.searchInstVO);
                if(response.status === 200){
                    var msg = '[' + localStorage.getItem("userBusinessName") + ']\n'
                    + ' - 구분 : 설치완료확인서'
                    + '\n - 일자 : ' + parameter.completePdf.inspectionPeriod
                    + '\n - 지역 : ' + parameter.completePdf.addrSido + ' ' + parameter.completePdf.addrSgg
                    + '\n - 가구수 : ' + parameter.completePdf.completePdfInstQuan + '가구'
                    + '\n - 센터명 : ' + parameter.completePdf.orgName

                if(!confirm(msg + '\n위 내용으로 전송하시겠습니까?')){
                    context.commit('END_LOADING');
                    return
                }
                    const param = {
                        receiver: parameter.checkerMobile,
                        msg: msg 
                            + '\n하단 URL을 통해 설치확인서 최종 검수 후 서명 진행 부탁드립니다.'
                            + '\n\n[URL]'
                            + `\n${process.env.VUE_APP_BASEURL_MOBILE}/#/external/completeList?value=`+ response.data.data
                    }
                    await requestUrlSmsSend(param)
                    prompt("해당 내용의 설치완료표가 전송되었습니다.",param.msg)
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
        },
        async REQUEST_INST_COMPLETELIST_DELETE(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestCompleteListDelete(parameter.searchCompletePdf);
                if(response.status === 200){
                    alert("설치완료표가 삭제되었습니다.")
                    parameter.context.complete();
                }
            }catch(e){
                console.log(e.response.data.resultMsg)
            }
            context.commit('END_LOADING');
        },
        async REQUEST_INST_INIT_INSPECTOR(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestInstInitInspector(parameter.searchInst);
                if(response.status === 201){
                    alert("점검자가 초기화 되었습니다.")
                    parameter.context.complete()
                }
            }catch(e){
                console.log(e.response.data.resultMsg)
            }
            context.commit('END_LOADING');
        },
        async REQUEST_INST_INIT_INSPECTORS(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestInstInitInspectors(parameter.searchInst);
                if(response.status === 201){
                    alert("점검자가 초기화 되었습니다.")
                    parameter.context.complete()
                }
            }catch(e){
                console.log(e.response.data.resultMsg)
            }
            context.commit('END_LOADING');
        },
        async REQUEST_INST_FINISH_FILE_DOWNLOAD(context, parameter){
            context.commit('START_LOADING');
            try{
                
                const response = await requestInstFinishFileDownload(parameter.instVOList);
                if(response.status === 200){
                    const url = URL.createObjectURL(new Blob([response.data]))
                      const link = document.createElement('a')
                      link.href = url
                      link.setAttribute('download', decodeURIComponent(response.headers.get("content-disposition").split("filename=")[1]))
                      document.body.appendChild(link)
                      link.click()
                      link.remove()
                }
            }catch(e){
                console.log(e.response.data.resultMsg)
            }
            context.commit('END_LOADING');
        }
    }
}