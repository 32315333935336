import {
    requestAsPage,
    requestAsSelect,
    requestAsCancelExcelDownload
} from "../apis/as_api";

import router from "@/router";

const pageType = 'cancel'

export const as_cancel = {
    nemespaced: true,
    
    state: () => ({
        asCancelStateCd:'STE004', //진행코드

        asCancelItems:[],
        asCancelTotalCount:0,
        asCancelItem:{},
    }),

    getters: {
        getAsCancelStateCd(state){
            return state.asCancelStateCd
        },
        getAsCancelItems(state){
            return state.asCancelItems
        },
        getAsCancelTotalCount(state){
            return state.asCancelTotalCount
        },
        getAsCancelItem(state){
            return state.asCancelItem
        },
    },

    mutations: {
        REQUEST_AS_CANCEL_PAGE(state,data){
            state.asCancelItems = data.data
            state.asCancelTotalCount = data.totalCount
        },
        REQUEST_AS_CANCEL_PAGE_INIT(state){
            state.asCancelItems = []
            state.asCancelTotalCount = 0
        },
        REQUEST_AS_CANCEL_SELECT(state,data){
            state.asCancelItem = data.data
            state.asCancelItem.asRequestDesc = JSON.parse(state.asCancelItem.asRequestDesc)
            let asRequestDescText = '';
            for(let i=0;i<state.asCancelItem.asRequestDesc.length;i++){
                asRequestDescText += state.asCancelItem.asRequestDesc[i].title + ' : ' + state.asCancelItem.asRequestDesc[i].value  + '\n'
            }
            state.asCancelItem.asRequestDescText = asRequestDescText
        },
        REQUEST_AS_CANCEL_SELECT_INIT(state){
            state.asCancelItem = {}
        },
    },

    actions: {
        async REQUEST_AS_CANCEL_PAGE(context,parameter) {
            context.commit('START_LOADING');
            try{
                const response = await requestAsPage(parameter.searchAs,pageType);
                if(response.status === 200){
                    context.commit('REQUEST_AS_CANCEL_PAGE',response.data);
                }
            }catch(e){
                console.log(e.response.data.resultMsg)  
            }
            context.commit('END_LOADING');
        },
        async REQUEST_AS_CANCEL_SELECT(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestAsSelect(parameter.searchAs,pageType);
                if(response.status === 200){
                    context.commit('REQUEST_AS_CANCEL_SELECT',response.data);
                }
            }catch(e){
                console.log(e.response.data.resultMsg)  
            }
            context.commit('END_LOADING');
        },
        async REQUEST_AS_CANCEL_EXCEL_DOWNLOAD(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestAsCancelExcelDownload(parameter.searchAs,pageType);
                if(response.status === 200){
                    const url = URL.createObjectURL(new Blob([response.data], {
                        type: 'application/vnd.ms-excel; charset=euc-kr'
                      }))
                      const link = document.createElement('a')
                      link.href = url
                      link.setAttribute('download', "AS취소list_"+ parameter.date +".xlsx")
                      document.body.appendChild(link)
                      link.click()
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
        },

    },

}