import {
    requestNoticeInsert,
    requestNoticePage,
    requestNoticeSelect,
    requestNoticeFileDownload,
    requestNoticeUpdate,
    requestNoticeDelete,
    requestNoticeHitsUp
} from "../apis/notice_api";
import router from "@/router";

export const notice = {
    nemespaced: true,
    
    state: () => ({
        noticeItems:[],
        noticeTotalCount:0,
        noticeItem:{},
    }),

    getters: {
        getNoticeItems(state){
            return state.noticeItems
        },
        getNoticeTotalCount(state){
            return state.noticeTotalCount
        },
        getNoticeItem(state){
            return state.noticeItem
        },
    },

    mutations: {
        REQUEST_NOTICE_PAGE(state,data){
            state.noticeItems = data.data
            state.noticeTotalCount = data.totalCount
        },
        REQUEST_NOTICE_PAGE_INIT(state){
            state.noticeItems = []
            state.noticeTotalCount = 0
        },
        REQUEST_NOTICE_SELECT(state,data){
            state.noticeItem = data.data

            const businessList = state.noticeItem.businessList
            state.noticeItem.businessList = []
            for(let i=0; i<businessList.length; i++) {
                state.noticeItem.businessList.push({
                    value: businessList[i].businessSchemaName,
                    label: businessList[i].businessName,
                })
            }

            const fileList = state.noticeItem.fileList
            for(let i=0; i<fileList.length; i++) {
                state.noticeItem.fileList[i].name = fileList[i].fileManagerOrgFileNm
                state.noticeItem.fileList[i].loadFile = true
            }
        },
        REQUEST_NOTICE_SELECT_INIT(state){
            state.noticeItem = {}
        },
    },

    actions: {
        async REQUEST_NOTICE_INSERT(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestNoticeInsert(parameter);
                if(response.status === 201){
                    parameter.context.complete();
                    alert("공지사항이 등록 되었습니다!")
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
    
        },
        async REQUEST_NOTICE_PAGE(context,parameter) {
            context.commit('START_LOADING');
            try{
                const response = await requestNoticePage(parameter.searchNoticeVO);
                if(response.status === 200){
                    context.commit('REQUEST_NOTICE_PAGE',response.data);
                }
            }catch(e){
                console.log(e.response.data.resultMsg)
            }
            context.commit('END_LOADING');
        },
        async REQUEST_NOTICE_SELECT(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestNoticeSelect(parameter.searchNoticeVO);
                if(response.status === 200){
                    context.commit('REQUEST_NOTICE_SELECT',response.data);
                }
            }catch(e){
                console.log(e.response.data.resultMsg)
            }
            context.commit('END_LOADING');
        },
        async REQUEST_NOTICE_FILE_DOWNLOAD(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestNoticeFileDownload(parameter.searchFileManagerVO);
                if(response.status === 200){
                    const url = URL.createObjectURL(new Blob([response.data], {
                        type: 'application/*; Content-Disposition;'
                      }))
                      const link = document.createElement('a')
                      link.href = url
                      link.setAttribute('download', parameter.searchFileManagerVO.fileManagerOrgFileNm)
                      document.body.appendChild(link)
                      link.click()
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
            
        },
        async REQUEST_NOTICE_UPDATE(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestNoticeUpdate(parameter);
                if(response.status === 201){
                    parameter.context.complete();
                    alert("공지사항이 수정 되었습니다!")
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
    
        },
        async REQUEST_NOTICE_DELETE(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestNoticeDelete(parameter.noticeVO);
                if(response.status === 200){
                    parameter.context.deleteComplete();
                    alert("공지사항이 삭제 되었습니다.")
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
    
        },
        async REQUEST_NOTICE_HITS_UP(context,parameter){
            try{
                const response = await requestNoticeHitsUp(parameter.searchNoticeVO);
                if(response.status === 201){
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)
            }
        },
    },

}