<template>
     <CFormInput :value="value" placeholder="01012345678" @keyup="isNumber" :maxlength="maxlength"/>
</template>
<script>
export default {
    name: 'NumberInput',
    props:['value', 'maxlength'],
    data() {
        return{
        }
    },
    mounted(){
        
    },
	methods: {
        isNumber(event) {  
            event.target.value = event.target.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');
            this.$emit('input', event.target.value);
            return true;
        },
    }
}
</script>