import axios from 'axios'

function requestAccountPage(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/system/account/page`, parameter)
}
function requestAccountSelect(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/system/account/select`, parameter)
}
function requestAccountUpdate(parameter) {
    return axios.post(`${process.env.VUE_APP_BASEURL}/system/account/update`, parameter)
}
function requestAccountInsert(parameter) {
    return axios.post(`${process.env.VUE_APP_BASEURL}/system/account/insert`, parameter)
}
function requestAccountListForAssign(page){
    return axios.post(`${process.env.VUE_APP_BASEURL}/${page}/set/account/list`)
}

export {
    requestAccountPage,
    requestAccountSelect,
    requestAccountUpdate,
    requestAccountInsert,
    requestAccountListForAssign
}