import {
    requestUserDuplicateIdCheck,
    requestAuthJoinCode,
    requestAuthPage,
    requestAccountList,
    requestCommonData,
    requestDashBoard,
    requestBusinessCodeSelect,
    requestBusinessAsCodeSelect
} from "../apis/common_api";
import {
    setAccountInfoLocalStorage
} from "@/utils/oauth";
import router from "@/router";
import { getStyle, hexToRgba } from '@coreui/utils/src'

export const common = {
    namaspaced: true,

    state: () => ({
        appBaseUrl:process.env.VUE_APP_BASEURL,
        joinAuthorityItems:[],
        partItems:[],
        joinBusinessItems:[],
        authorityItems:[],
        sidoItems:[],
        accountInfoItems:[],
        sigunguItems:[],
        orgmItems:[],
        checkListStatistics:{},
        checkListStatisticsGraph:{
            labels:[],
            datasets:[]
        },
        asStatistics:{},
        instStatistics:{},
        graphData:{
            labels:[],
            datasets:[]
        },
        loginMenuItems:[],
        businessCodeItems:[],
        userAccountName: localStorage.getItem("userAccountName"),

        loadingModalFlag:false,
        loadingFlag:false,

        
        pdfDistributeItems:[
            {label: '구분', value: 'ALL'},
            {label: '신규설치', value: '3'},
            {label: '재설치', value: '1'},
            {label: '변경설치', value: '2'},
        ],
        pdfDistributeForAsItems:[
            {label: '구분', value: 'ALL'},
            {label: '재설치', value: '1'},
            {label: '변경설치', value: '2'},
        ],
    }),

    getters: {
        getPdfDistributeItems(state){
            return state.pdfDistributeItems
        },
        getPdfDistributeForAsItems(state){
            return state.pdfDistributeForAsItems
        },
        getAppBaseUrl(state){
            return state.appBaseUrl
        },
        getJoinAuthorityItems(state){
            return state.joinAuthorityItems
        },
        getCodeItemsWithKey(state){
            return keyword => state.partItems.filter(item =>{
                return item.codeCdGroup === keyword || item.codeCdGroup === ''
            });
        },
        getJoinBusinessItems(state){
            return state.joinBusinessItems
        },
        getAuthorityItems(state){
            return state.authorityItems
        },
        getSidoItems(state){
            return state.sidoItems
        },
        getAccountInfoItems(state){
            return state.accountInfoItems
        },
        getSigunguItems(state){
            return state.sigunguItems
        },
        getOrgItems(state){
            return state.orgmItems
        },
        getLoginMenuItems(state){
            return state.loginMenuItems
        },
        getCheckListStatistics(state){
            return state.checkListStatistics
        },
        getAsStatistics(state){
            return state.asStatistics
        },
        getInstStatistics(state){
            return state.instStatistics
        },
        getDashboardGraphData(state){
            return state.graphData
        },
        getUserAccountName(state){
            return state.userAccountName
        },
        getLoadingModalFlag(state){
            return state.loadingModalFlag
        },
        getLoadingFlag(state){
            return state.loadingFlag
        },
        getBusinessCodeItemsWithKey(state){
            return (keyword1, keyword2) => state.businessCodeItems.filter(item =>{
                if(keyword2!=null){
                    return item.codeCdGroup === keyword1 && keyword2.indexOf(item.parentCd) != -1
                } else {
                    return item.codeCdGroup === keyword1
                }
            });
        },
        getCheckListStatisticsGraph(state){
            return state.checkListStatisticsGraph
        },
    },

    mutations: {
        START_MODAL_LOADING(state){
            state.loadingModalFlag = true
        },
        END_MODAL_LOADING(state){
            state.loadingModalFlag = false
        },
        START_LOADING(state){
            state.loadingFlag = true
        },
        END_LOADING(state){
            state.loadingFlag = false
        },

        REQUEST_AUTH_JOIN_CODE(state,data){ 

            const authorityList = data.authorityList
            state.joinAuthorityItems=[];

            for(let i=0; i<authorityList.length; i++) {
                state.joinAuthorityItems.push({
                label: authorityList[i].authorityName,
                value: authorityList[i].authorityId,
                });
            }
            
            const businessList = data.businessList
            state.joinBusinessItems=[];
            for(let i=0; i<businessList.length; i++) {
                state.joinBusinessItems.push({
                label: businessList[i].businessName,
                value: businessList[i].businessSchemaName,
                });
            }

            const commonCodeList = data.commonCodeList
            state.partItems=[];
            for(let i=0; i<commonCodeList.length; i++) {
                state.partItems.push({
                label: commonCodeList[i].codeName,
                value: commonCodeList[i].codeCd,
                codeCdGroup: commonCodeList[i].codeCdGroup,
                });
            }
        },
        REQUEST_AUTH_PAGE(state,data){
            state.authorityItems = data
        },
        REQUEST_COMMON_DATA_INIT(state){
            state.accountInfoItems = [];
            state.sidoItems=[];
            state.sigunguItems=[];
            state.orgmItems=[];
            state.loginMenuItems=[];
            state.userAccountName='';
        },
        MAKE_COMMON_DATE(state,data){
            setAccountInfoLocalStorage(data)
            if(data.accountInfo!=null){
                state.userAccountName = data.accountInfo.accountName
            }

            const accountInfoList = data.accountInfoList
            state.accountInfoItems=[];
            state.accountInfoItems.push({label: '거래처', value: '',cmmnCdGroup:''});
            for(let i=0; i<accountInfoList.length; i++) {
            state.accountInfoItems.push({
                label: accountInfoList[i].accountName,
                value: accountInfoList[i].accountId,
            });
            }


            const sidoList = data.sidoList
            state.sidoItems=[];
            state.sidoItems.push({label: '시도', value: '',cmmnCdGroup:''});
            for(let i=0; i<sidoList.length; i++) {
            state.sidoItems.push({
                label: sidoList[i].addrSido,
                value: sidoList[i].addrSidoCd,
            });
            }
            const sigunguList = data.sigunguList
            state.sigunguItems=[];
            state.sigunguItems.push({label: '시군구', value: '',sidoCd:'',sido:''});
            for(let i=0; i<sigunguList.length; i++) {
            state.sigunguItems.push({
                label: sigunguList[i].addrSgg,
                value: sigunguList[i].addrSigunguCd,
                sidoCd: sigunguList[i].addrSidoCd,
                sido:  sigunguList[i].addrSido,
            });
            }

            const orgList = data.orgList
            state.orgmItems=[];
            state.orgmItems.push({label: '관리기관', value: ''});
            for(let i=0; i<orgList.length; i++) {
                state.orgmItems.push({
                    label: orgList[i].orgName,
                    value: orgList[i].orgId,
                    sigunguCd: orgList[i].addrSigunguCd,
                    sidoCd: orgList[i].addrSidoCd,
                });
            }

            const commonCodeList = data.commonCodeList
            state.partItems=[];
            for(let i=0; i<commonCodeList.length; i++) {
                state.partItems.push({
                label: commonCodeList[i].codeName,
                value: commonCodeList[i].codeCd,
                codeCdGroup: commonCodeList[i].codeCdGroup,
                });
            }


        
            const firMenuList = data.firMenuList
            const secMenuList = data.secMenuList
            state.loginMenuItems=[];
            state.loginMenuItems.push(
                {
                    component: 'CNavItem',
                    name: '메인',
                    to: '/dashboard',
                    icon: 'cil-speedometer',
                    badge: {
                      color: 'coustom',
                      text: 'NEW',
                    },
                }
            );
            for(let i=0; i<firMenuList.length; i++) {
    
                state.loginMenuItems.push(
                    {
                      component: 'CNavGroup',
                      to: firMenuList[i].menuPath,
                      name: firMenuList[i].menuName,
                      icon: firMenuList[i].menuIcon,
                      items:[],
                    }
                )
                
                for(let j=0; j<secMenuList.length; j++) {
                    if(secMenuList[j].menuParent === firMenuList[i].menuId){
                        state.loginMenuItems[i+1].items.push(
                            {
                                component: 'CNavItem',
                                name: secMenuList[j].menuName,
                                to: secMenuList[j].menuPath,
                            }
                        )
                    }
                }
            }
        },
        REQUEST_DASHBOARD(state,data){
            debugger
            var asStatisticGraph = data.data.asStatisticGraph
            var asStatisticGraphLabel = []
            var asStatisticGraphData = []
            var asStatisticGraphColor = ['#3396FF','#FF9933','#D7D6D6','#F9D300','#00B5F9','#00CB0C','#0251BC','#BC5102','#6F6F6F']
            for(var i=0;i<asStatisticGraph.length;i++){
                asStatisticGraphLabel.push(asStatisticGraph[i].asCodeFirstName);
                asStatisticGraphData.push(asStatisticGraph[i].asCodeFirstHashtagTagCnt);

            }
            state.checkListStatisticsGraph.datasets.push(
                {
                    backgroundColor: asStatisticGraphColor,
                    data: asStatisticGraphData,
                  },
            )
            state.checkListStatisticsGraph.labels = asStatisticGraphLabel
            state.checkListStatistics = data.data.asStatisticGraph


            
            

            state.asStatistics = data.data.asStatistics
            state.instStatistics = data.data.instStatistics

            state.graphData.labels = data.data.graphLabel
            state.graphData.datasets.push(
                {
                  label: '설치완료',
                  backgroundColor: 'transparent',
                  borderColor: getStyle('--cui-primary'),
                  pointHoverBackgroundColor: getStyle('--cui-primary'),
                  borderWidth: 2,
                  data: data.data.instData,
                  pointRadius: 10,
                  pointBackgroundColor: getStyle('--cui-primary'),
                },
                {
                  label: '점검표완료',
                  backgroundColor: 'transparent',
                  borderColor: getStyle('--cui-success'),
                  pointHoverBackgroundColor: getStyle('--cui-success'),
                  borderWidth: 2,
                  borderDash: [2, 2],
                  data: data.data.checkListData,
                  pointRadius: 10,
                  pointBackgroundColor: getStyle('--cui-success'),
                },
                {
                  label: 'AS 완료',
                  backgroundColor: hexToRgba(getStyle('--cui-info'), 10),
                  borderColor: getStyle('--cui-info'),
                  pointHoverBackgroundColor: getStyle('--cui-info'),
                  borderWidth: 2,
                  borderDash: [8, 5],
                  data: data.data.asData,
                  pointRadius: 10,
                  pointBackgroundColor: getStyle('--cui-info'),
                },
            )
        },
        REQUEST_DASHBOARD_INIT(state,data){
            state.checkListStatistics = {}
            state.asStatistics = {}
            state.instStatistics = {}
            state.graphData={
                labels:[],
                datasets:[]
            }
            
            state.checkListStatisticsGraph = {
                labels:[],
                datasets:[]
            }

        },
        REQUEST_BUSINESS_CODE_SELECT(state,data){
            const businessCodeItems = data.data
            state.businessCodeItems=[];
            for(let i=0; i<businessCodeItems.length; i++) {
                if(businessCodeItems.codeUseYn){
                    state.businessCodeItems.push({
                        label: businessCodeItems[i].codeName,
                        value: businessCodeItems[i].codeCd,
                        codeCdGroup: businessCodeItems[i].codeCdGroup,
                        parentCd: businessCodeItems[i].codeParentCd
                    });
                }
            }
        },
    },

    actions: {
        async REQUEST_USER_DUPLICATE_ID_CHECK(context,parameter){
            context.commit('START_LOADING');
            const param = {}
            try{
                const response = await requestUserDuplicateIdCheck(parameter.userVO);
        
                if(response.status === 200){
                    param.data = response.data
                }
            }catch(e){
                console.log(e.response.data.resultMsg)
            }
            context.commit('END_LOADING');
            return param
        },
        async REQUEST_AUTH_JOIN_CODE(context){
            context.commit('START_LOADING');
            try{
                const response = await requestAuthJoinCode();
                if(response.status === 200){
                    context.commit('REQUEST_AUTH_JOIN_CODE',response.data);
                }
            }catch(e){
                console.log(e.response.data.resultMsg)
            }
            context.commit('END_LOADING');
        },
        async REQUEST_AUTH_PAGE(context){
            context.commit('START_LOADING');
            try{
                const response = await requestAuthPage();
                if(response.status === 200){
                    context.commit('REQUEST_AUTH_PAGE',response.data);
                }
            }catch(e){
                console.log(e.response.data.resultMsg)
            }
            context.commit('END_LOADING');
        },
        async REQUEST_ACCOUNT_LIST(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestAccountList(parameter);
                if(response.status === 200){
                    context.commit('END_LOADING');
                    return response
                }
            }catch(e){
                console.log(e.response.data.resultMsg)
            }
            context.commit('END_LOADING');
        },
        async REQUEST_COMMON_DATA(context) {
            try {
                context.commit('START_LOADING');
                const response = await requestCommonData();
                if(response.status === 200){
    
                    context.commit('MAKE_COMMON_DATE',response.data);
                }
            } catch (e) { 
                console.log(e.response.data.resultMsg)
            }
            context.commit('END_LOADING');
        },
        async REQUEST_DASHBOARD(context) {
            try {
                context.commit('START_LOADING');
                const response = await requestDashBoard();
                if(response.status === 200){
                    context.commit('REQUEST_DASHBOARD',response.data);
                }
            } catch (e) { 
                console.log(e.response.data.resultMsg)
            }
            context.commit('END_LOADING');
        },
        async REQUEST_BUSINESS_CODE_SELECT(context){
            context.commit('START_LOADING');
            try{
                const response = await requestBusinessCodeSelect();
                if(response.status === 200){
                    context.commit('REQUEST_BUSINESS_CODE_SELECT',response.data);
                }
            }catch(e){
                console.log(e.response.data.resultMsg)                
            }
            context.commit('END_LOADING');
        },
    }
}