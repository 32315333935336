import {
    requestAccountPage,
    requestAccountSelect,
    requestAccountUpdate,
    requestAccountInsert,
    requestAccountListForAssign
} from "../apis/account_api";

import router from "@/router";

export const account = {
    nemespaced: true,
    
    state: () => ({
        accountItems:[],
        accountTotalCount:0,
        accountItem:{},
        accountUserList:[],
        accountItemsForAssign: [],
        accountSelectBox:[{label: '선택', value: ''}]
    }),

    getters: {
        getAccountItems(state){
            return state.accountItems
        },
        getAccountTotalCount(state){
            return state.accountTotalCount
        },
        getAccountItem(state){
            return state.accountItem
        },
        getAccountUserList(state){
            return state.accountUserList
        },
        getAccountSelectBox(state){
            return state.accountSelectBox
        },
    },

    mutations: {
        REQUEST_ACCOUNT_PAGE(state,data){
            state.accountItems = data.data
            state.accountTotalCount = data.totalCount
        },
        REQUEST_ACCOUNT_PAGE_INIT(state){
            state.accountItems = []
            state.accountTotalCount = 0
        },
        REQUEST_ACCOUNT_SELECT(state,data){
            state.accountItem = data.data
            state.accountUserList = data.data.accountUserList
        },
        REQUEST_ACCOUNT_INIT(state){
            state.accountItem = {}
        },
        REQUEST_ACCOUNT_LIST_INIT(state){
            state.accountItemsForAssign = []
            state.accountSelectBox =[{label: '선택', value: ''}]
        },
        REQUEST_ACCOUNT_LIST_FOR_ASSIGN(state,data){
            state.accountItemsForAssign = data.data

            const accountList = data.data
            for(let i=0; i<accountList.length; i++) {
                state.accountSelectBox.push({
                    label: accountList[i].accountName,
                    value: accountList[i].accountId,
                });
            }
        },
    },

    actions: {
        async REQUEST_ACCOUNT_PAGE(context,parameter) {
            context.commit('START_LOADING');
            try{
                const response = await requestAccountPage(parameter.searchAccountVO);
                if(response.status === 200){
                    context.commit('REQUEST_ACCOUNT_PAGE',response.data);
                }
            }catch(e){
                console.log(e.response.data.resultMsg)  
            }
            context.commit('END_LOADING');
        },
        async REQUEST_ACCOUNT_SELECT(context,parameter) {
            context.commit('START_LOADING');
            try{
                const response = await requestAccountSelect(parameter.searchAccountVO);
                if(response.status === 200){
                    context.commit('REQUEST_ACCOUNT_SELECT',response.data);
                }
            }catch(e){
                console.log(e.response.data.resultMsg)  
            }
            context.commit('END_LOADING');
        },
        async REQUEST_ACCOUNT_UPDATE(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestAccountUpdate(parameter.searchAccountVO);
                if(response.status === 201){
                    alert("거래처 수정이 완료되었습니다!")
                    parameter.context.complete();
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
        },
        async REQUEST_ACCOUNT_INSERT(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestAccountInsert(parameter.searchAccountVO);
                if(response.status === 201){
                    alert("거래처 생성이 완료되었습니다!")
                    parameter.context.complete();
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
        },
        async REQUEST_ACCOUNT_LIST_FOR_ASSIGN(context,page) {
            context.commit('START_LOADING');
            try{
                const response = await requestAccountListForAssign(page);
                if(response.status === 200){
                    context.commit('REQUEST_ACCOUNT_LIST_FOR_ASSIGN',response.data);
                }
            }catch(e){
                console.log(e.response.data.resultMsg)  
            }
            context.commit('END_LOADING');
        },
    },

}