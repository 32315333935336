import {
    requestAsStaticList,
    requestAsStaticExcelDownload,
    requestAsStaticDetailPage,
    requestAsStaticDetailExcelDownload,
    requestAsStaticComfirmDetailPage,
    requestAsStaticComfirmDetailExcelDownload
} from "../apis/as_api";



export const as_static = {
    nemespaced: true,
    
    state: () => ({
        asStaticItems:[],
        asStaticTotalCount:0,
        asStaticDetailItems:[],
        asStaticDetailTotalCount:0,
        asStaticComfirmDetailItems:[],
        asStaticComfirmDetailTotalCount:0,
    }),

    getters: {
        getAsStaticItems(state){
            return state.asStaticItems
        },
        getAsStaticTotalCount(state){
            return state.asStaticTotalCount
        },
        getAsStaticDetailItems(state){
            return state.asStaticDetailItems
        },
        getAsStaticDetailTotalCount(state){
            return state.asStaticDetailTotalCount
        },
        getAsStaticComfirmDetailItems(state){
            return state.asStaticComfirmDetailItems
        },
        getAsStaticComfirmDetailTotalCount(state){
            return state.asStaticComfirmDetailTotalCount
        },
    },

    mutations: {
        REQUEST_AS_STATIC_LIST(state,data){
            state.asStaticItems = data.data
            state.asStaticTotalCount = data.totalCount
        },
        REQUEST_AS_STATIC_LIST_INIT(state){
            state.asStaticItems = []
            state.asStaticTotalCount = 0
        },
        REQUEST_AS_STATIC_DETAIL_PAGE(state,data){
            state.asStaticDetailItems = data.data
            state.asStaticDetailTotalCount = data.totalCount
        },
        REQUEST_AS_STATIC_DETAIL_PAGE_INIT(state){
            state.asStaticDetailItems = []
            state.asStaticDetailTotalCount = 0
        },
        
        REQUEST_AS_STATIC_COMFIRM_DETAIL_PAGE(state,data){
            state.asStaticComfirmDetailItems = data.data
            state.asStaticComfirmDetailTotalCount = data.totalCount
        },
        REQUEST_AS_STATIC_COMFIRM_DETAIL_PAGE_INIT(state){
            state.asStaticComfirmDetailItems = []
            state.asStaticComfirmDetailTotalCount = 0
        },
    },

    actions: {
        async REQUEST_AS_STATIC_LIST(context,parameter) {
            context.commit('START_LOADING');
            try{
                const response = await requestAsStaticList(parameter.searchAs);
                if(response.status === 200){
                    context.commit('REQUEST_AS_STATIC_LIST',response.data);
                }
            }catch(e){
                console.log(e.response.data.resultMsg)  
            }
            context.commit('END_LOADING');
        },
        async REQUEST_AS_STATIC_EXCEL_DOWNLOAD(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestAsStaticExcelDownload(parameter.searchAs);
                if(response.status === 200){
                    const url = URL.createObjectURL(new Blob([response.data], {
                        type: 'application/vnd.ms-excel; charset=euc-kr'
                      }))
                      const link = document.createElement('a')
                      link.href = url
                      link.setAttribute('download', "유지보수접수통계현황_"+ parameter.date +".xlsx")
                      document.body.appendChild(link)
                      link.click()
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
        },

        async REQUEST_AS_STATIC_DETAIL_PAGE(context,parameter) {
            context.commit('START_LOADING');
            try{
                const response = await requestAsStaticDetailPage(parameter.searchAs);
                if(response.status === 200){
                    context.commit('REQUEST_AS_STATIC_DETAIL_PAGE',response.data);
                }
            }catch(e){
                console.log(e.response.data.resultMsg)  
            }
            context.commit('END_LOADING');
        },
        async REQUEST_AS_STATIC_DETAIL_EXCEL_DOWNLOAD(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestAsStaticDetailExcelDownload(parameter.searchAs);
                if(response.status === 200){
                    const url = URL.createObjectURL(new Blob([response.data], {
                        type: 'application/vnd.ms-excel; charset=euc-kr'
                      }))
                      const link = document.createElement('a')
                      link.href = url
                      link.setAttribute('download', "유지보수접수통계상세현황_"+ parameter.date +".xlsx")
                      document.body.appendChild(link)
                      link.click()
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
        },

        
        async REQUEST_AS_STATIC_COMFIRM_DETAIL_PAGE(context,parameter) {
            context.commit('START_LOADING');
            try{
                const response = await requestAsStaticComfirmDetailPage(parameter.searchAs);
                if(response.status === 200){
                    context.commit('REQUEST_AS_STATIC_COMFIRM_DETAIL_PAGE',response.data);
                }
            }catch(e){
                console.log(e.response.data.resultMsg)  
            }
            context.commit('END_LOADING');
        },
        async REQUEST_AS_STATIC_COMFIRM_DETAIL_EXCEL_DOWNLOAD(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestAsStaticComfirmDetailExcelDownload(parameter.searchAs);
                if(response.status === 200){
                    const url = URL.createObjectURL(new Blob([response.data], {
                        type: 'application/vnd.ms-excel; charset=euc-kr'
                      }))
                      const link = document.createElement('a')
                      link.href = url
                      link.setAttribute('download', "유지보수완료통계상세현황_"+ parameter.date +".xlsx")
                      document.body.appendChild(link)
                      link.click()
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
        },
    },

}