<template>
  <CInputGroup class="mb-3">
    <CFormInput id="inputGroupFile01" @change="upload" accept="image/*" type="file" v-model="temp"/>
  </CInputGroup>
  <CRow v-if="file!=null">
      <CCol :lg="4">
        <div class="square">
          <CImage class="inner" fluid :src="makePath(image)"/>
          <CImage class="delete-img" fluid :src="deleteBtn" @click="deleteFile()"/>
        </div>
      </CCol>
  </CRow>
</template>
<script>
import deleteBtn from '@/assets/images/deleteBtn.png'
import { mapGetters } from "vuex";
export default {
  name: 'CommonImgUpload',
  props: [],
  data() {
    return {
      file: null,
      image: null,
      deleteBtn:deleteBtn,
      temp:''
    }
  },
    computed: {
        ...mapGetters(['getAppBaseUrl']),
    },
  mounted(){
  },
  beforeUnmount(){
  },
  methods: {
    upload(e) {
      const files = e.target.files;
      let url = URL.createObjectURL(files[0]);
      this.file = files[0]
      this.image = {
        loadFile:false
        ,path:url
      }
      this.emit()
    },
    deleteFile(){
      this.file = null
      this.image = null
      this.temp = ''
      this.emit()
    },
    emit(){
      const parameter = {
        file: this.file,
        image: this.image
      }
      this.$emit('imagUpload',parameter)
    },
    makePath(image){
      if(image.loadFile){
        return this.getAppBaseUrl+ '/display?fileSvrPath='+encodeURI(image.path)
      }else{
        return image.path
      }
    }
  }
}
</script>

<style lang="scss">
.card-body {
  padding: 0px;
}
.square {
  width: 100%;
  position: relative;
  display: inline-block;
}

.square:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.inner {
  position: absolute;
  width: 100%;
  height: 100%;
}

.delete-img{
  width: 15%;
  height: 15%;
  float: right;
  position: absolute;
}
</style>
