import {
    requestAsPage,
    requestAsStateUpdate,
    requestAsAssignCancel,
    requestAsListStateUpdate,
    requestAsSelect,
    requestAsInsert,
    requestAssInsert,
    requestPeriodOverPage,
    requestAsExcelDownload,
    requestAsUpdate,
    requestBusinessAsCodeSelect,
    requestBusinessAsCodeInsert,
    requestBusinessAsCodeUpdate,
    requestBusinessAsCodeDelete
} from "../apis/as_api";

import router from "@/router";

const pageType = 'set'

export const as_set = {
    nemespaced: true,
    
    state: () => ({
        asSetStateCd:'STE001', //진행코드

        asSetItems:[],
        asSetTotalCount:0,
        asSetItem:{},

        asPeriodOverItems:[],
        asPeriodOverTotalCount:0,

        businessAsFirstCodes:[],
        businessAsSecondCodes:[],
        businessAsThirdCodes:[],

        businessAsFirstCodesForSelectBox:[],
        businessAsSecondCodesForSelectBox:[],
        businessAsThirdCodesForSelectBox:[],
    }),

    getters: {
        getAsSetStateCd(state){
            return state.asSetStateCd
        },
        getAsSetItems(state){
            return state.asSetItems
        },
        getAsSetTotalCount(state){
            return state.asSetTotalCount
        },
        getAsSetItem(state){
            return state.asSetItem
        },

        getPeriodOverItems(state){
            return state.asPeriodOverItems
        },
        getPeriodOverTotalCount(state){
            return state.asPeriodOverTotalCount
        },
        getBusinessAsFirstCode(state){
            return state.businessAsFirstCodes
        },
        getBusinessAsSecondCode(state){
            return (keyword) => state.businessAsSecondCodes.filter(item =>{
                return keyword === item.codeParentCd
            });
        },
        getBusinessAsThirdCode(state){
            return (keyword) => state.businessAsThirdCodes.filter(item =>{
                return keyword === item.codeParentCd
            });
        },
        getBusinessAsFirstCodeForSelectBox(state){
            return state.businessAsFirstCodesForSelectBox
        },
        getBusinessAsSecondCodeForSelectBox(state){
            return (keyword) => state.businessAsSecondCodesForSelectBox.filter(item =>{
                return keyword === item.codeParentCd
            });
        },
        getBusinessAsThirdCodeForSelectBox(state){
            return (keyword) => state.businessAsThirdCodesForSelectBox.filter(item =>{
                return keyword === item.codeParentCd
            });
        },

    },

    mutations: {
        REQUEST_AS_SET_PAGE(state,data){
            state.asSetItems = data.data
            state.asSetTotalCount = data.totalCount
        },
        REQUEST_AS_SET_PAGE_INIT(state){
            state.asSetItems = []
            state.asSetTotalCount = 0
        },
        REQUEST_AS_SET_SELECT(state,data){
            state.asSetItem = data.data
            state.asSetItem.asRequestDesc = JSON.parse(state.asSetItem.asRequestDesc)
            let asRequestDescText = '';
            for(let i=0;i<state.asSetItem.asRequestDesc.length;i++){
                asRequestDescText += state.asSetItem.asRequestDesc[i].title + ' : ' + state.asSetItem.asRequestDesc[i].value  + '\n'
            }
            state.asSetItem.asRequestDescText = asRequestDescText
        },
        REQUEST_AS_SET_SELECT_INIT(state){
            state.asSetItem = {}
        },
        REQUEST_PERIOD_OVER_PAGE(state,data){
            state.asPeriodOverItems = data.data
            state.asPeriodOverTotalCount = data.totalCount
        },
        REQUEST_PERIOD_OVER_PAGE_INIT(state){
            state.asPeriodOverItems = []
            state.asPeriodOverTotalCount = 0
        },
        REQUEST_BUSINESS_AS_CODE_SELECT(state,data){
            state.businessAsFirstCodes = data.data.firstCodeList
            state.businessAsSecondCodes = data.data.secondCodeList
            state.businessAsThirdCodes = data.data.thirdCodeList

            const firstCodeList = data.data.firstCodeList
            state.businessAsFirstCodesForSelectBox=[];
            for(let i=0; i<firstCodeList.length; i++) {
                if(firstCodeList[i].codeUseYn){
                    state.businessAsFirstCodesForSelectBox.push({
                        label: firstCodeList[i].codeName,
                        value: firstCodeList[i].codeCd,
                    });
                }
            }
            const secondCodeList = data.data.secondCodeList
            state.businessAsSecondCodesForSelectBox=[];
            for(let i=0; i<secondCodeList.length; i++) {
                if(secondCodeList[i].codeUseYn){
                    state.businessAsSecondCodesForSelectBox.push({
                        label: secondCodeList[i].codeName,
                        value: secondCodeList[i].codeCd,
                        codeParentCd: secondCodeList[i].codeParentCd
                    });
                }
            }
            const thirdCodeList = data.data.thirdCodeList
            state.businessAsThirdCodesForSelectBox=[];
            for(let i=0; i<thirdCodeList.length; i++) {
                if(thirdCodeList[i].codeUseYn){
                    state.businessAsThirdCodesForSelectBox.push({
                        label: thirdCodeList[i].codeName,
                        value: thirdCodeList[i].codeCd,
                        codeParentCd: thirdCodeList[i].codeParentCd
                    });
                }
            }
        },
    },

    actions: {
        async REQUEST_AS_SET_PAGE(context,parameter) {
            context.commit('START_LOADING');
            try{
                const response = await requestAsPage(parameter.searchAs,pageType);
                if(response.status === 200){
                    context.commit('REQUEST_AS_SET_PAGE',response.data);
                }
            }catch(e){
                console.log(e.response.data.resultMsg)  
            }
            context.commit('END_LOADING');
        },
        async REQUEST_AS_ASSIGN(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestAsStateUpdate(parameter.searchAsVO);
                if(response.status === 201){
                    parameter.context.complete();
                    alert("기사배정을 성공하였습니다.")
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
        },
        async REQUEST_AS_ASSIGN_CANCEL(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestAsAssignCancel(parameter.searchAsVO);
                if(response.status === 200){
                    parameter.context.complete();
                    alert("기사배정을 제거하였습니다.")
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
        },
        async REQUEST_AS_BATCH_ASSIGN(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestAsListStateUpdate(parameter.searchAsList);
                if(response.status === 201){
                    parameter.context.complete();
                    alert("기사배정을 성공하였습니다.")
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
        },
        async REQUEST_AS_SET_SELECT(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestAsSelect(parameter.searchAsVO,pageType);
                if(response.status === 200){
                    context.commit('REQUEST_AS_SET_SELECT',response.data);
                }
            }catch(e){
                console.log(e.response.data.resultMsg)  
            }
            context.commit('END_LOADING');
        },
        async REQUEST_AS_INSERT(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestAsInsert(parameter.searchAsVO);
                if(response.status === 201){
                    alert("예약등록을 성공하였습니다.")
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
        },
        async REQUEST_ASS_INSERT(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestAssInsert(parameter.searchAsList);
                if(response.status === 201){
                    alert("예약등록을 성공하였습니다.")
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
            
        },
        async REQUEST_PERIOD_OVER_PAGE(context,parameter) {
            context.commit('START_LOADING');
            try{
                const response = await requestPeriodOverPage(parameter.searchAs);
                if(response.status === 200){
                    context.commit('REQUEST_PERIOD_OVER_PAGE',response.data);
                }
            }catch(e){
                console.log(e.response.data.resultMsg)  
            }
            context.commit('END_LOADING');
        },
        async REQUEST_AS_INST_EXCEL_DOWNLOAD(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestAsExcelDownload(parameter.searchAs,pageType);
                if(response.status === 200){
                    const url = URL.createObjectURL(new Blob([response.data], {
                        type: 'application/vnd.ms-excel; charset=euc-kr'
                      }))
                      const link = document.createElement('a')
                      link.href = url
                      link.setAttribute('download', "AS예약list_"+ parameter.date +".xlsx")
                      document.body.appendChild(link)
                      link.click()
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
        },
        async REQUEST_AS_SET_UPDATE(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestAsUpdate(parameter.searchAs,pageType);
                if(response.status === 201){
                    alert("취소되었습니다.")
                    parameter.context.complete(parameter)
                }
            }catch(e){
                console.log(e.response.data.resultMsg)  
            }
            context.commit('END_LOADING');
        },
        async REQUEST_BUSINESS_AS_CODE_SELECT(context){
            context.commit('START_LOADING');
            try{
                const response = await requestBusinessAsCodeSelect();
                if(response.status === 200){
                    context.commit('REQUEST_BUSINESS_AS_CODE_SELECT',response.data);
                }
            }catch(e){
                console.log(e.response.data.resultMsg)                
            }
            context.commit('END_LOADING');
        },
        async REQUEST_BUSINESS_AS_CODE_INSERT(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestBusinessAsCodeInsert(parameter.searchCodeVO);
                if(response.status === 201){
                    alert("코드를 추가했습니다.")
                    parameter.context.complete()
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
            
        },
        async REQUEST_BUSINESS_AS_CODE_UPDATE(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestBusinessAsCodeUpdate(parameter.searchCodeVO);
                if(response.status === 201){
                    alert("코드를 수정했습니다.")
                    parameter.context.complete()
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
            
        },
        async REQUEST_BUSINESS_AS_CODE_DELETE(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestBusinessAsCodeDelete(parameter.searchCodeVO);
                if(response.status === 200){
                    alert("코드를 삭제했습니다.")
                    parameter.context.complete()
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
            
        },
    },

}