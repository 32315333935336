import {
    requestAsPage,
    requestAsSelect,
    requestAsStatisticPage,
    requestAsExcelDownload,
    requestAsStatisticExcelDownload,
    requestAsUpdate,
    requestAsInitInspector
} from "../apis/as_api";

import router from "@/router";
import { getStyle, hexToRgba } from '@coreui/utils/src'

const pageType = 'finish'

export const as_finish = {
    nemespaced: true,
    
    state: () => ({
        asFinishStateCd:'STE003', //진행코드

        asFinishItems:[],
        asFinishTotalCount:0,
        asFinishItem:{},

        asStatisticItems:[],
        asStatisticTotalCount:0,
        asStatisticComfirmGraph:{
            labels:[],
            datasets:[]
        },
        asStatisticRegGraph:{
            labels:[],
            datasets:[]
        },

        
    }),

    getters: {
        getAsFinishStateCd(state){
            return state.asFinishStateCd
        },
        getAsFinishItems(state){
            return state.asFinishItems
        },
        getAsFinishTotalCount(state){
            return state.asFinishTotalCount
        },
        getAsFinishItem(state){
            return state.asFinishItem
        },
        getAsStatisticItems(state){
            return state.asStatisticItems
        },
        getAsStatisticComfirmGraph(state){
            return state.asStatisticComfirmGraph
        },
        getAsStatisticRegGraph(state){
            return state.asStatisticRegGraph
        },
        getAsStatisticTotalCount(state){
            return state.asStatisticTotalCount
        },
    },

    mutations: {
        REQUEST_AS_FINISH_PAGE(state,data){
            state.asFinishItems = data.data
            state.asFinishTotalCount = data.totalCount
        },
        REQUEST_AS_FINISH_PAGE_INIT(state){
            state.asFinishItems = []
            state.asFinishTotalCount = 0
        },
        REQUEST_AS_FINISH_SELECT(state,data){
            state.asFinishItem = data.data
            state.asFinishItem.asRequestDesc = JSON.parse(state.asFinishItem.asRequestDesc)
            let asRequestDescText = '';
            for(let i=0;i<state.asFinishItem.asRequestDesc.length;i++){
                asRequestDescText += state.asFinishItem.asRequestDesc[i].title + ' : ' + state.asFinishItem.asRequestDesc[i].value  + '\n'
            }
            state.asFinishItem.asRequestDescText = asRequestDescText
        },
        REQUEST_AS_FINISH_SELECT_INIT(state){
            state.asFinishItem = {}
        },
        REQUEST_AS_STATISTIC_PAGE(state,data){
            state.asStatisticItems = data.data
            state.asStatisticTotalCount = data.totalCount
        },
        
        REQUEST_AS_STATISTIC_COMFIRM_GRAPH(state,data){
            debugger
            const item = data.data
            const labels = []
            const graphDatas = []
            const graphDataRegs = []
            for(var i= item.length-1 ;-1<i;i--){
                labels.push(item[i].statisticDate);
                graphDatas.push(Number(item[i].asFinishStatistic));
                graphDataRegs.push(Number(item[i].asRegStatistic));
            }
            
            state.asStatisticComfirmGraph.labels = labels
            state.asStatisticComfirmGraph.datasets.push(
                {
                    animation: false,
                    label: '유지보수 완료 통계',
                    backgroundColor: '#00B050',
                    borderColor: '#00B050',
                    pointHoverBackgroundColor: getStyle('--cui-success'),
                    borderWidth: 2,
                    borderDash: [2, 2],
                    data: graphDatas,
                  },
            )


            
            state.asStatisticRegGraph.labels = labels
            state.asStatisticRegGraph.datasets.push(
                {
                    animation: false,
                    label: '유지보수 접수 통계',
                    backgroundColor: '#FF0000',
                    borderColor: '#FF0000',
                    pointHoverBackgroundColor: getStyle('--cui-success'),
                    borderWidth: 2,
                    borderDash: [2, 2],
                    data: graphDataRegs,
                  },
            )

        },

        REQUEST_AS_STATISTIC_PAGE_INIT(state){
            state.asStatisticItems = []
            state.asStatisticTotalCount = 0
            state.asStatisticComfirmGraph = {
                labels:[],
                datasets:[]
            }

            
            state.asStatisticRegGraph = {
                labels:[],
                datasets:[]
            }
        },
    },

    actions: {
        async REQUEST_AS_FINISH_PAGE(context,parameter) {
            context.commit('START_LOADING');
            try{
                const response = await requestAsPage(parameter.searchAs,pageType);
                if(response.status === 200){
                    context.commit('REQUEST_AS_FINISH_PAGE',response.data);
                }
            }catch(e){
                console.log(e.response.data.resultMsg)  
            }
            context.commit('END_LOADING');
        },
        async REQUEST_AS_FINISH_SELECT(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestAsSelect(parameter.searchAs,pageType);
                if(response.status === 200){
                    context.commit('REQUEST_AS_FINISH_SELECT',response.data);
                }
            }catch(e){
                
            }
            context.commit('END_LOADING');
        },
        async REQUEST_AS_STATISTIC_PAGE(context,parameter) {
            context.commit('START_LOADING');
            try{
                const response = await requestAsStatisticPage(parameter.searchAsHistory);
                if(response.status === 200){
                    context.commit('REQUEST_AS_STATISTIC_PAGE',response.data);
                    context.commit('REQUEST_AS_STATISTIC_COMFIRM_GRAPH',response.data);
                    
                }
            }catch(e){
                console.log(e.response.data.resultMsg)  
            }
            context.commit('END_LOADING');
        },
        async REQUEST_AS_FINISH_EXCEL_DOWNLOAD(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestAsExcelDownload(parameter.searchAs,pageType);
                if(response.status === 200){
                    const url = URL.createObjectURL(new Blob([response.data], {
                        type: 'application/vnd.ms-excel; charset=euc-kr'
                      }))
                      const link = document.createElement('a')
                      link.href = url
                      link.setAttribute('download', "AS완료list_"+ parameter.date +".xlsx")
                      document.body.appendChild(link)
                      link.click()
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
        },
        async REQUEST_AS_STATISTIC_EXCEL_DOWNLOAD(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestAsStatisticExcelDownload(parameter.searchAsHistory,pageType);
                if(response.status === 200){
                    const url = URL.createObjectURL(new Blob([response.data], {
                        type: 'application/vnd.ms-excel; charset=euc-kr'
                      }))
                      const link = document.createElement('a')
                      link.href = url
                      link.setAttribute('download', "AS통계현황_"+ parameter.date +".xlsx")
                      document.body.appendChild(link)
                      link.click()
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
        },
        async REQUEST_AS_FINISH_UPDATE(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestAsUpdate(parameter.searchAs,pageType);
                if(response.status === 201){
                    alert("진행상태로 변경 되었습니다.")
                    parameter.context.complete(parameter)
                }
            }catch(e){
                console.log(e.response.data.resultMsg)  
            }
            context.commit('END_LOADING');
        },
        async REQUEST_AS_INIT_INSPECTOR(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestAsInitInspector(parameter.searchAs);
                if(response.status === 201){
                    alert("점검자가 초기화 되었습니다.")
                    parameter.context.complete()
                }
            }catch(e){
                console.log(e.response.data.resultMsg)
            }
            context.commit('END_LOADING');
        },
    },

}