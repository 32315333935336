import axios from 'axios'

function requestUserInsert(parameter) {
    const formData = new FormData()
    for ( var key in parameter.userVO ) {
        if(parameter.userVO[key]!==null){
            if(key == 'userBusinessMappingList'){
                parameter.userVO[key].forEach((value, index, array)=>{
                    formData.append("userBusinessMappingList["+index+"].businessSchemaName", value.value)
                  })
            }else if(key == 'userAccountList'){
                parameter.userVO[key].forEach((value, index, array)=>{
                    formData.append("userBusinessMappingList["+index+"].accountId", value)
                  })
            } else{
                formData.append(key, parameter.userVO[key])
            }
        }
    }
    formData.append('file', parameter.file)
    return axios.post(`${process.env.VUE_APP_BASEURL}/user/insert`, formData)
}

function requestLogin(parameter) {
    return axios.post(`${process.env.VUE_APP_BASEURL}/auths/login`, parameter)
}

function requestFindUser(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/auths/find-user`, parameter)
}

function requestFindUserId(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/auths/find-userid`, parameter)
}

function requestUserPage(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/system/user/page`, parameter)
}

function requesUserBusinessList(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/system/user/business/list`, parameter)
}

function requestUserUpdateForAdmin(parameter){
    const formData = new FormData()
    for ( var key in parameter.userVO ) {
        if(parameter.userVO[key]!==null){
          if(key == 'userBusinessMappingList'){
                parameter.userVO[key].forEach((value, index, array)=>{
                    formData.append("userBusinessMappingList["+index+"].businessSchemaName", value.value)
                  })
            }else if(key == 'userAccountList'){
                parameter.userVO[key].forEach((value, index, array)=>{
                    formData.append("userBusinessMappingList["+index+"].accountId", value)
                  })
            } else{
                formData.append(key, parameter.userVO[key])
            }
        }
    }
    formData.append('file', parameter.file)
    return axios.post(`${process.env.VUE_APP_BASEURL}/system/user/forAdmin/update`, formData)
}

function requestInstallerList(parameter,page){
    return axios.post(`${process.env.VUE_APP_BASEURL}/${page}/set/installer/list`, parameter)
}

function requestUserSelect(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/system/user/select`, parameter)
}

function requestUserElecContractUpload(parameter){
    const formData = new FormData()
    for ( var key in parameter.userVO ) {
        if(parameter.userVO[key]!==null){
            if(key == 'userBusinessMappingList'){
                continue
            }else if(key == 'userAccountList'){
                continue
            } else{
                formData.append(key, parameter.userVO[key])
            }
        }
    }
    formData.append('file', parameter.file)
    return axios.post(`${process.env.VUE_APP_BASEURL}/system/user/elec-contract/upload`, formData)
}

function requestUserElecContractDownload(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/system/user/elec-contract/download`, parameter, {responseType: 'blob'})
}

function requesUserInfoBusinessList(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/userInfo/business/list`, parameter)
}
function requestUserInfoUpdate(parameter){
    const formData = new FormData()
    for ( var key in parameter.userVO ) {
        if(parameter.userVO[key]!==null){
            if(key == 'userBusinessMappingList'){
                continue
            }else if(key == 'userAccountList'){
                continue
            } else{
                formData.append(key, parameter.userVO[key])
            }
        }
    }
    formData.append('file', parameter.file)
    return axios.post(`${process.env.VUE_APP_BASEURL}/userInfo/update`, formData)
}
function requestUserInfoSelect(parameter){
    return axios.post(`${process.env.VUE_APP_BASEURL}/userInfo/select`, parameter)
}

function requestLogout() {
    return axios.post(`${process.env.VUE_APP_BASEURL}/auths/logout`)
}

export {
    requestUserInsert,
    requestLogin,
    requestUserPage,
    requesUserBusinessList,
    requestUserUpdateForAdmin,
    requestInstallerList,
    requestUserSelect,
    requestFindUser,
    requestFindUserId,
    requestUserElecContractUpload,
    requestUserElecContractDownload,
    requesUserInfoBusinessList,
    requestUserInfoUpdate,
    requestUserInfoSelect,
    requestLogout
}