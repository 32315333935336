import {
	requestBusinessPage,
    requestBusinessSchemaDuplicateCheck,
    requestBusinessInsert,
    requestBusinessSelect,
    requestBusinessUpdate,
    requestBusinessPrivacyUpdate
} from "../apis/business_api";

import router from "@/router";

export const business = {
    nemespaced: true,
    
    state: () => ({
        businessItems:[],
        businessTotalCount:0,
        businessItem:{},
        businessMenuList:[],
    }),

    getters: {
        getBusinessItems(state){
            return state.businessItems
        },
        getBusinessTotalCount(state){
            return state.businessTotalCount
        },
        getBusinessItem(state){
            return state.businessItem
        },
        getBusinessMenuList(state){
            return state.businessMenuList
        },
    },

    mutations: {
        REQUEST_BUSINESS_PAGE(state,data){
            state.businessItems = data.data
            state.businessTotalCount = data.totalCount
        },
        REQUEST_BUSINESS_PAGE_INIT(state){
            state.businessItems = []
            state.businessTotalCount = 0
        },
        REQUEST_BUSINESS_SELECT(state,data){
            state.businessItem = data.data
            state.businessMenuList = data.data.businessMenuList
        }
    },

    actions: {
        async REQUEST_BUSINESS_PAGE(context,parameter) {
            context.commit('START_LOADING');
            try{
                const response = await requestBusinessPage(parameter.searchBusinessVO);
                if(response.status === 200){
                    context.commit('REQUEST_BUSINESS_PAGE',response.data);
                }
            }catch(e){
                console.log(e.response.data.resultMsg)  
            }
            context.commit('END_LOADING');
        },
        async REQUEST_BUSINESS_SCHEMA_CHECK(context,parameter){
            context.commit('START_LOADING');
            const param = {}
            try{
                const response = await requestBusinessSchemaDuplicateCheck(parameter.searchBusinessVO);
                if(response.status === 200){
                    param.data = response.data
                }
            }catch(e){
                console.log(e.response.data.resultMsg)  
            }
            context.commit('END_LOADING');
            return param
        },
        async REQUEST_BUSINESS_INSERT(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestBusinessInsert(parameter.searchBusinessVO);
                if(response.status === 201){
                    parameter.context.insertBusinessMenu();
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
        },
        async REQUEST_BUSINESS_SELECT(context,parameter) {
            context.commit('START_LOADING');
            try{
                const response = await requestBusinessSelect(parameter.searchBusinessVO);
                if(response.status === 200){
                    context.commit('REQUEST_BUSINESS_SELECT',response.data);
                }
            }catch(e){
                console.log(e.response.data.resultMsg)  
            }
            context.commit('END_LOADING');
        },
        async REQUEST_BUSINESS_UPDATE(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestBusinessUpdate(parameter.searchBusinessVO);
                if(response.status === 201){
                    alert("사업단 수정이 완료되었습니다!")
                    parameter.context.complete();
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
        },
        async REQUEST_BUSINESS_PRIVACY_UPDATE(context,parameter){
            context.commit('START_LOADING');
            try{
                const response = await requestBusinessPrivacyUpdate(parameter.searchBusinessVO);
                if(response.status === 201){
                    alert("시스템설정 수정이 완료되었습니다!")
                    parameter.context.complete();
                }
            }
            catch(e){
                console.log(e.response.data.resultMsg)   
            }
            context.commit('END_LOADING');
        },
    },

}